import axios from "axios";

export const state = { topics: {}, topicsPromise: {} };

export const getters = {
  topics: (state) => (contract_id) => state.topics[contract_id]
};

export const mutations = {
  saveTopics(state, { topics, contractId }) {
    let contract_id = contractId || "all";
    if (state.topics[contract_id]?.length)
      state.topics[contract_id] = [...topics, ...state.topics[contract_id]];
    else
      state.topics[contract_id] = topics;
  },
  saveTopicsPromise(state, { promise, contractId }) {
    let contract_id = contractId || "all";
    state.topicsPromise[contract_id] = promise;
  },
};

export const actions = {
  async fetch({ commit, rootState }) {
    if (!rootState.contract.currentContract) return [];
    const contractId = rootState.contract.currentContract.id;
    if (contractId && state.topicsPromise[contractId]) return state.topicsPromise[contractId];
    if (!contractId && state.topicsPromise["all"]) return state.topicsPromise["all"];

    let promise = axios.get("/v3/sentiment/topics", { params: { subscription_id: contractId } }).then(response => {
      const topics = response.data.data;
      commit("saveTopics", { topics, contractId });
      return topics;
    });

    commit("saveTopicsPromise", { promise, contractId });
    return promise;
  },
}
