<script>
import PageHeader    from "@/components/page-header";
import Response      from  "@/components/response";
import Review        from "@/components/review";
import Filters       from "@/components/filter";
import axios         from "axios";
import Utils         from "@/js/utils";
import DataUtils     from "@/js/data-utils";
import FeedbackUtils from "@/js/feedback-utils";
import sources       from "@/data/sources";

export default {
  data() {
    return {
      filterCols: ["daterange", "group_companies", "has_sentiment", "topics", "with_responses", "companies", "rating_range", "sources", "reviewer_country", "travel_composition", "language", "page", "source_type", "packages"],
      params: { page: 1 },
      loading: false,
      totalItems: 0,
      itemsPerPage: 20,
      totalPages: 1,
      cache: {},
      api_permissions: [],
      yesNoOptions: { true: 'yes', false: 'no' },
      translations: { [this.$i18n.locale]: {} },
      filterParams: {},
      replyPermissions: {},
      sources: {},
      connectionLink: null,
      feedbackContracts: [],
      currentPageParams: {},
      user: {},
      selectedItem: null
    };
  },
  components: { PageHeader, Filters, Response, Review },
  created() {
    this.$store.dispatch("user/fetch").then(data => {
      this.user = data;
      this.api_permissions   = data.api_permissions;
      this.feedbackContracts = data.feedback_contracts;
      this.selectedItem      = this.$route.query.rid;
    })
  },
  mounted() {
    window.addEventListener("scroll", this.scrollBtnDisplay);
    window.addEventListener("keydown", this.navigateBetweenItems);
    this.sources = sources;
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.scrollBtnDisplay);
    window.removeEventListener("keydown", this.navigateBetweenItems);
  },
  watch: {
    contract: {
      handler: function () {
        if (this.contract?.oauth_token?.length) this.connectionLink = `https://review-connect.com/oauth?oauth_token=${this.contract.oauth_token}`;
      },
      immediate: true
    },
    "$i18n.locale": function () {
      if (!this.translations[this.$i18n.locale]) Object.values(this.cache).forEach(item => item.forEach(r => r.translated = false))
    },
    filterParams: function () {
      this.loadListener(this.filterParams);
    }
  },
  computed: {
    contract() {
      return this.$store.getters["contract/currentContract"];
    },
    feedbackContract() {
      if (this.contract.id) return this.feedbackContracts.find(c => c.id == this.contract.id);
      return {};
    },
    showDownloadButton() {
      return this.totalItems;
    }
  },
  methods: {
    ...DataUtils,
    ...FeedbackUtils,
    async loadListener(params) {
      this.cache = {};
      // correct the path for the topics filter
      params.data.topic = params.data.topics;
      let st = params.data.source_type;
      st = Array.isArray(st) ? st[0] : st;
      this.params = {
        page : Array.isArray(params.data.page) ? parseInt(params.data.page[0] || 1) : parseInt(params.data.page || 1),
        limit: this.itemsPerPage,
        compositions: params.data.travel_composition,
        countries:    params.data.reviewer_country,
        languages:    params.data.language,
        ...(st != "all" && { type: st }),
        ...(params.data.with_responses != "all" && { with_response: params.data.with_responses == "with" }),
        ...Utils.slice(params.data, ["start_date", "end_date", "company_ids", "group_ids", "rating_range", "sources", "has_sentiment", "topic", "packages"])
      };
      this.params = { ...this.params, ...Utils.slice(params.data, ["sources", "topic", "compositions", "countries", "languages", "packages"].map(f => `exclude_${f}`)) };

      await this.loadFeed();
    },
    async changePage() {
      if (!this.cache[this.params.page]) await this.loadFeed();
      this.resetExpandButtons();
    },
    async loadFeed() {
      Utils.setLoading.bind(this)(true);
      this.cache[this.params.page] = [];
      this.currentPageParams = Utils.deepClone(this.params);

      let response = await axios.get("/v3/reviews/feed", { params: { ...this.params, notes: true } });

      let items = response.data.data;
      items.forEach(item => {
        ["translated"].forEach(field => item[field] = false);
        item.show = true;
      });

      this.cache[this.params.page] = items;
      this.totalItems = response.data.count;
      this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage);

      Utils.setLoading.bind(this)(false);
      await this.loadReplyPermissions();
      this.scrollToItem();

      return this.cache[this.params.page];
    },
    async loadReplyPermissions() {
      let promises = [], ids = [];
      this.cache[this.params.page].filter(item => item.type == "review").forEach(review => {
        if (Utils.permittedReviewSources.includes(review.source) && review.company.id && (this.replyPermissions[review.company.id] == undefined)) ids.push(review.company.id);
      });

      ids = [...new Set(ids)];
      ids.forEach(id => {
        promises.push(axios.get(`/v3/oauth/credentials/${id}/active`).then(res => this.replyPermissions[id] = res.data.data?.length > 0));
      });
      await Promise.all(promises);
    },
    async pageChange() {
      await this.$router.push({ path: this.$route.path, query: { ...this.$route.query, "pg": this.params.page } });
      this.filterParams.data.page = this.params.page;
      await this.changePage();
      this.$nextTick(() => window.scrollTo(0, 0));
    },
    scrollBtnDisplay() {
      let scrollBtn = document.querySelector("#scroll-btn")
      window.scrollY > window.innerHeight
        ? scrollBtn?.classList.add("show")
        : scrollBtn?.classList.remove("show");
    },
    scrollWindow(x = 0, y = 0) {
      setTimeout(() => {
        window.scrollTo({
          top: y,
          left: x,
          behavior: "smooth",
        });
      }, 10);
    },
    async downloadXLSX() {
      Utils.setLoading.bind(this)(true);
      let response = await axios.get("/v3/reviews/feed.xlsx", { params: { ...this.currentPageParams, limit: null, page: null }, responseType: "blob" });
      let blob = new Blob([response.data], { type: "" });
      let link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `${this.$t("daily_operations.reviews.title", { p: this.params.page })}.xlsx`;
      link.click();
      URL.revokeObjectURL(link.href);
      Utils.setLoading.bind(this)(false);
    },
    updateReply(item, review) {
      const i = item.comments.findIndex(c => c.kind == "manager");
      if (i >= 0) item.comments[i].comment = review.comment;
    },
    selectItem(id) {
      this.selectedItem = id;
      this.$router.push({ name: this.$route.name, query: { ...this.$route.query, rid: id } });
    },
    scrollToItem(offset = 0) {
      if (!(this.selectedItem && this.$refs[this.selectedItem])) return;
      const review = Array.isArray(this.$refs[this.selectedItem]) ? this.$refs[this.selectedItem][0] : this.$refs[this.selectedItem];
      if (!review) return;
      const top = review.$el.getBoundingClientRect().top + window.scrollY - offset;
      this.scrollWindow(0, top);
    },
    navigateBetweenItems(ev) {
      if (!/ArrowUp|ArrowDown/.test(ev.key)) return;
      const itemIndex = this.cache[this.params.page].findIndex(i => i.id == this.selectedItem);
      let newIndex = itemIndex;
      if (ev.key == "ArrowUp")   newIndex -= 1;
      if (ev.key == "ArrowDown") newIndex += 1;
      if (newIndex >= this.itemsPerPage || newIndex < 0 || newIndex >= this.totalItems) return;
      if (this.cache[this.params.page][newIndex]) this.selectItem(this.cache[this.params.page][newIndex].id);
      this.scrollToItem(100);
    }
  }
};
</script>

<template>
  <div class="w-100" :class="{ 'cursor-loading': loading }">
    <div class="d-flex align-items-center justify-content-between">
      <PageHeader :title="$t('daily_operations.reviews.title')" />
      <b-dropdown v-if="connectionLink" class="px-0" size="lg" variant="link" dropleft toggle-class="text-decoration-none text-secondary border border-secondary" no-caret>
        <template #button-content>
          <i class="mdi mdi-dots-vertical color-secondary"></i>
        </template>

        <b-dropdown-text> {{ $t("daily_operations.reviews.options") }} </b-dropdown-text>

        <b-dropdown-item class="d-block" :href="connectionLink" target="_blank" rel="noopener noreferrer">
          <div class="d-flex align-items-center">
            <i class="mdi mdi-link-variant font-size-18 me-1"></i>
            <p class="m-0 font-size-14"> {{ $t("daily_operations.reviews.connect") }} </p>
          </div>
        </b-dropdown-item>
      </b-dropdown>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <!-- Filters -->

        <Filters @filter:created="saveFilterParams" @filter:update="saveFilterParams" @filter:update_page="changePage" :cols="filterCols" :preventSubmit="true" />

        <!-- Time line -->
        <div v-if="!filterParams.data" class="card bg-light select-group-company">{{ $t("general.select_group_or_company") }}</div>
        <div class="card" v-if="filterParams.data">
          <div class="card-body">
            <button v-if="showDownloadButton" class="btn btn-secondary float-end" @click="downloadXLSX" v-b-tooltip.hover :title="$t('daily_operations.reviews.download_tooltip')">{{ $t("general.export_excel") }}</button>
            <div class="row justify-content-center my-5">
              <div class="col-lg-10">
                <ul class="verti-timeline list-unstyled" dir="ltr" v-if="!loading">
                  <template v-for="(item, itemIndex) in cache[params.page]" :key="item.id">
                    <Response v-if="item.type == 'response' && item.show && item.company" :response="item" :responseIndex="itemIndex" :cache="cache" :params="params" :translations="translations" :api_permissions="api_permissions" :contract="feedbackContract" @click="selectItem(item.id)" :class="{ 'review-selected': selectedItem == item.id }" :ref="String(item.id)" />
                    <Review v-else-if="item.type == 'review' && item.show && item.company " :review="item" :reviewIndex="itemIndex" :cache="cache" :params="params" :translations="translations" :permittedReply="replyPermissions[item.company.id]" :sources="sources" :user="user" @updateReply="r => updateReply(item, r)" @click="selectItem(item.id)" :class="{ 'review-selected': selectedItem == item.id }" :ref="String(item.id)" />
                  </template>
                </ul>

                <div style='min-height: 200px;' class="loading" v-if="loading"></div>

                <div class="d-flex align-items-center justify-content-center" v-if="!loading && totalItems == 0"> <span class="font-size-16"> {{ $t("general.no_data") }} </span> </div>

                <b-pagination
                  v-if="totalItems / itemsPerPage > 0"
                  class="d-flex justify-content-center mt-5"
                  v-model="params.page"
                  :total-rows="totalItems"
                  :per-page="itemsPerPage"
                  aria-controls="responses-pages"
                  @update:modelValue="pageChange"
                ></b-pagination>

                <button id="scroll-btn" @click="scrollWindow()"> &uarr; </button>
              </div>
            </div>
            <!-- end row -->
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
    <!-- end row -->
  </div>
</template>
