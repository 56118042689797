<script>
import PageHeader from "@/components/page-header";
import Filters    from "@/components/filter";
import DataUtils  from "@/js/data-utils";
import Modal from "@/components/modal-users-reports";
import Utils from "@/js/utils";
import ReputationUtils from "@/js/reputation-utils";
import axios from "axios";
import dayjs from "dayjs";
import Swal  from "sweetalert2";

export default {
  data() {
    return {
      filterCols: ['group_companies', "companies", 'search', 'report_templates'],
      filterParams: {},
      requests: {},
      initialLoadLimit: 100,
      perPage: 25,
      currentPage: 1,
      totalRequests: 0,
      nextURL: null,
      loading: true,
      sortBy: "count",
      sortDesc: true,
      modals: { request_modal: false },
      user: null,
      users: {},
      myData: { assignments: { groups: [], companies: [] } },
      reportTemplates: {}
    };
  },
  components: { PageHeader, Filters, Modal },
  watch: {
    contract: {
      handler: function () {
        if (this.contract?.id) this.load();
      },
      immediate: true
    }
  },
  methods: {
    ...DataUtils,
    ...ReputationUtils,
    async load() {
      if (this.users[this.contract.id]) return;
      Utils.setLoading.bind(this)(true);
      if (!this.user) this.user = await this.$store.dispatch("user/fetch");
      this.users[this.contract.id] = await this.$store.dispatch("users/fetch", { contract_id: this.contract.id });
      let me = this.users[this.contract.id].find(u => u.id == this.user?.id);
      if (!me && this.user.manager) me = await this.loadManagerPermissions();
      this.myData = Utils.deepClone(me);

      await this.loadRequests(true);
      await this.loadReportTemplates();
      Utils.setLoading.bind(this)(false);
    },
    async loadRequests(firstLoad) {
      if (!this.nextURL && !firstLoad) return;
      if (!this.requests[this.contract.id]) this.requests[this.contract.id] = [];

      let response;
      if (this.nextURL) response = await axios.get(this.nextURL);
      else if (firstLoad) response = await axios.get("/v3/reports/requests", { params: { subscription_ids: this.contract.id, limit: this.initialLoadLimit, page: this.currentPage } });

      this.nextURL = response.data.next_url;
      this.totalRequests = response.data.count;
      this.requests[this.contract.id] = this.requests[this.contract.id].concat(response.data.data);
      this.requests[this.contract.id].forEach(s => this.formatRequest(s));
    },
    async loadReportTemplates() {
      if (this.reportTemplates[this.contract.id]) return;
      this.reportTemplates[this.contract.id] = await this.$store.dispatch("reportTemplates/fetch", { contract_id: this.contract.id });
    },
    formatRequest(request) {
      if (request.user.name) request.requester = `${request.user.name} <${request.user.email}>`;
      else request.requester = request.user.email;
      ["created_at", "email_sent_at", "start_date", "end_date"].forEach(field => {
        if (request[field]) request[field] = dayjs(request[field]).format("DD MMM YYYY");
      });
    },
    columns(k) {
      let fields = [
        { [k]: "id",            text: this.$t('reports.id'),         sortable: true },
        { [k]: "requester",     text: this.$t('reports.requester'),  sortable: true },
        { [k]: "template.name", text: this.$t('reports.template'),   sortable: true },
        { [k]: "properties",    text: this.$t('reports.properties'), sortable: true },
        { [k]: "start_date",    text: this.$t('reports.start_date'), sortable: true },
        { [k]: "end_date",      text: this.$t('reports.end_date'),   sortable: true },
        { [k]: "created_at",    text: this.$t('reports.created_at'), sortable: true },
        { [k]: "email_sent_at", text: this.$t('reports.sent_at'),    sortable: true },
        { [k]: "actions",       text: this.$t('reports.actions'),    sortable: true },
      ]
      return fields;
    },
    downloadFile({ item, propertyId }, zipURL) {
      if (zipURL) return;
      if (propertyId) this.downloadPDF(item, propertyId);
      else if (item.documents?.length == 1) this.downloadPDF(item, null, item.documents[0]);
      else this.downloadZIP(item);
    },
    async downloadZIP(item) {
      Utils.setLoading.bind(this)(true);
      let response = await axios.get(`/v3/reports/requests/${item.id}/documents`, { responseType: "blob" });
      let blob = new Blob([response.data], { type: "application/zip" });
      let link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `${item.template.name} - ${item.start_date} - ${item.end_date}.zip`;
      link.click();
      URL.revokeObjectURL(link.href);
      Utils.setLoading.bind(this)(false);
    },
    async downloadPDF(item, propertyId, document) {
      let tab = window.open("", "_blank");
      let doc = item.documents.find(d => d.filename.includes(` - ${propertyId}`)) || document;
      tab.document.write(this.$t("reports.loading_report"));
      let response = await axios.get(`/v3/reports/requests/${item.id}/documents/${doc.id}`, { responseType: "blob" });
      let blob = new Blob([response.data], { type: "application/pdf" });
      tab.location.href = URL.createObjectURL(blob);
      Utils.setLoading.bind(this)(false);
    },
    async resendReport(item) {
      try {
        let params = {
          user_id:     item.user.id,
          identifier:  item.template.identifier,
          start_date:  dayjs(item.start_date).toISOString(),
          end_date:    dayjs(item.end_date).toISOString(),
          company_ids: item.companies.map(c => c.id),
          group_ids:   item.groups.map(g => g.id),
          schedule_id: item.schedule.id,
          contract_id: this.contract.id
        };

        if (item.user.id != this.user.id) {
          let response = await Swal.fire({
            title: this.$t("general.are_you_sure"),
            html:  this.confirmMessage(item.user),
            icon: "question",
            showCancelButton:  true,
            showConfirmButton: true,
            allowOutsideClick: false
          });
          if (!response.isConfirmed) return;
        }

        Utils.setLoading.bind(this)(true);
        await axios.post("/v2/report-requests", params);
        this.info();
      } catch (err) { this.error(err); }
      Utils.setLoading.bind(this)(false);
    },
    openModal() {
      this.modals.request_modal = true;
    },
    formatUser(item) {
      let userPermissions = this.users[this.contract.id].find(u => u.id == item.user.id)?.assignments;
      let groups = item.groups.map(g => userPermissions.groups.find(up => up.id == g.id));
      let user = { ...item.user, full_name: item.user.name, assignments: { companies: item.companies, groups: groups }, options: userPermissions, report_template_id: item.template.id };
      return user;
    },
    info() {
			Swal.fire({ text: this.$t("reports.send_request_info"), icon: "info", showCancelButton: false, showConfirmButton: true, allowOutsideClick: true, backdrop: true });
		},
    error(error) {
      Swal.fire(this.$t("general.error"), this.$t("general.contact_support") + JSON.stringify(error.response?.data), "error");
      Utils.setLoading.bind(this)(false);
    },
    confirmMessage(user) {
      return this.$t("reports.resend_to_another_user", { email: user.email }).replace(/\n/g, "<br><br>");
    },
    hasDocument(item, propertyId) {
      return item.documents.find(d => d.filename.includes(` - ${propertyId}`));
    },
    async goToPage(to) {
      if ((this.requests[this.contract.id].length < this.totalRequests) && (this.currentPage == (this.requests[this.contract.id].length / this.perPage))) {
        Utils.setLoading.bind(this)(true);
        await this.loadRequests(false);
        Utils.setLoading.bind(this)(false);
      }

      if (to == "previous")  this.currentPage--;
      else if (to == "next") this.currentPage++;
      else if (to)           this.currentPage = to;

      this.$refs["requests_table"]?.updatePage(this.currentPage);
    }
  },
  computed: {
    contract() {
      return this.$store.getters["contract/currentContract"];
    },
    filteredRequests() {
      if (!this.contract?.id || !this.requests[this.contract.id]) return []
      let p = this.filterParams
      if (!p.data && !p.report_templates) return this.requests[this.contract.id]

      let requests = [...this.requests[this.contract.id]]

      if (p.data.search) {
        let str   = p.data.search.toUpperCase();
        requests = requests.filter(s => {
          let nameEmail = (s.user.name || "").toUpperCase() + '|' + s.user.email.toUpperCase()
          return nameEmail.includes(str)
        })
      }

      if (p.report_templates?.length) requests = requests.filter(s => p.report_templates.includes(s.template.id));

      if (p.data?.company_ids) requests = requests.filter(s => s.companies.find(c => p.data.company_ids.includes(c.id)));
      if (p.data?.group_ids) requests = requests.filter(s => s.groups.find(g => p.data.group_ids.includes(String(g.id))));

      if (requests.length != this.requests[this.contract.id].length) this.goToPage(1);

      return requests;
    },
    disabledNav() {
      let res = { previous: "", next: "" };
      if (this.currentPage == 1) res.previous = "disabled";
      if (this.currentPage == Math.ceil(this.totalRequests / this.perPage)) res.next = "disabled";
      return res;
    }
  }
}
</script>

<template>
  <div class="w-100">
    <PageHeader :title="$t('reports.report_deliveries')" :tooltip="$t('reports.requests_help')">
      <template v-slot:subtitle>
        <button class="btn btn-primary" @click="openModal()">{{ $t("reports.new_request") }}</button>
      </template>
    </PageHeader>

    <Filters @filter:created="saveFilterParams" @filter:change="saveFilterParams" :cols='filterCols' :notifyChange='true' />

    <div class="row">
      <div class="col-xl-12">
        <div style='min-height: 200px;' class="card" :class="{'loading': loading}">
          <div class="card-body">
            <data-table
              ref="requests_table"
              class="thead-light"
              :items="filteredRequests"
              :headers="columns('value')"
              :rows-per-page="perPage"
              header-text-direction="center"
              body-text-direction="center"
              buttons-pagination
              theme-color="#90a0cb"
              :current-page="currentPage"
              :sort-by="sortBy"
              sort-type="desc"
              hide-footer
            >
              <template #item-period="item">
                {{ this.periods[item.period] || item.period }}
              </template>

              <template #item-properties="item">
                <template v-for="property in ['groups', 'companies']" :key="`${item.id}_${property}`">
                  <template v-if="item[property] && item[property].length">
                    <div class="d-flex justify-content-center flex-wrap align-items-center">
                      <strong class="text-darken-blue mt-2">{{ $t(`reports.${property}`) }}:&nbsp;</strong>
                      <template v-for="p in item[property]" :key="p">
                        <button v-if="hasDocument(item, p.id)" @click="downloadFile({ item, propertyId: p.id })" class="token-primary me-2 mt-2" v-b-tooltip.hover :title="$t('reports.download_tooltip', { p: p.name })">
                          {{ p.name }}
                        </button>
                        <span v-else class="mt-2">
                          {{ p.name }}
                        </span>
                      </template>
                    </div>
                  </template>
                </template>
              </template>

              <template #item-actions="item">
                <a :href="item.zip_url || '#'" :target="item.zip_url ? '_blank' : ''" @click="downloadFile({ item }, item.zip_url)" class='text-nowrap me-3 text-start' >
                  <i class="mdi mdi-download"></i>
                  {{ $t('reports.download', { ext: item.documents.length == 1 && !item.zip_url ? "PDF" : "ZIP" }) }}
                </a>

                <a href='#' @click="resendReport(item)" class='text-nowrap me-3 text-start' >
                  <i class="mdi mdi-send"></i>
                  {{ $t('reports.resend') }}
                </a>
              </template>

              <template #empty-message>
                {{ $t("general.no_data") }}
              </template>
            </data-table>

            <nav class="float-end mt-3" v-if="filteredRequests.length > perPage">
              <ul class="pagination">
                <li class="page-item" :class="disabledNav.previous"><a class="page-link" href="#" @click="goToPage('previous')">{{ $t("general.previous") }}</a></li>
                <li class="page-item" :class="disabledNav.next"><a class="page-link" href="#" @click="goToPage('next')">{{ $t("general.next") }}</a></li>
              </ul>
            </nav>

            <b-modal v-model="modals.request_modal" centered hide-footer hide-header size="lg">
              <Modal v-if="modals.request_modal" context="report_request" :contract="contract" :modals="modals" :title="$t('reports.add_request')" type="report_request" modalId="request_modal" :reportTemplates="reportTemplates[contract.id]" :myData="myData" :users="users[contract.id]" />
            </b-modal>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
