<script>
import PageHeader       from "@/components/page-header";
import Filters          from "@/components/filter";
import DataUtils        from "@/js/data-utils";
import TemplateSettings from "../template-settings";
import Duplicate        from "./duplicate";

import axios from "axios";
import dayjs from "dayjs";
import Utils from "@/js/utils";
import Swal  from "sweetalert2";

export default {
  data() {
    return {
      filterCols: ["search", "periods"],
      filterParams: {},
      perPage: 50,
      currentPage: 1,
      templatesCount: 0,
      loading: true,
      sortBy: "count",
      sortDesc: true,
      modals: {
        new_template: false,
        duplicate_template: false
      },
      reportTemplates: {},
      newTemplate: {
        name: "",
        period: "month",
        based_on: null
      }
    };
  },
  components: { PageHeader, Filters, TemplateSettings, Duplicate },
  watch: {
    contract: {
      handler: function () {
        if (this.contract?.id) this.load();
      },
      immediate: true
    }
  },
  methods: {
    ...DataUtils,
    ...Utils.date,
    async load() {
      if (this.reportTemplates[this.contract.id]) return;
      Utils.setLoading.bind(this)(true);
      this.reportTemplates[this.contract.id] = await this.$store.dispatch("reportTemplates/fetch", { contract_id: this.contract.id });
      Utils.setLoading.bind(this)(false);
    },
    async createNewTemplate() {
      const dt = await axios.post('/v3/dashboard_templates', { subscription_id: this.contract.id, components: [], styles: [] })
      this.newTemplate['dashboard_template_id'] = dt.data.data.id

      const response = await axios.post('/v3/reports/templates', { ...this.newTemplate, subscription_id: this.contract.id })
      this.reportTemplates[this.contract.id] = this.reportTemplates[this.contract.id].concat(response.data.data)
      this.$store.commit("reportTemplates/saveReportTemplates", { reportTemplates: this.reportTemplates, params: { contract_id: this.contract.id } });

      this.redirectToEdition(response.data.data)
    },
    duplicateTemplateCondition(item) {
      this.item = item
      if (this.user.admin) {
        this.modals.duplicate_template = true;
      } else
        this.duplicateTemplate(this.contract.id)
    },
    async duplicateTemplate(contract_id, user_id) {
      Utils.setLoading.bind(this)(true);
      const response = await axios.post(`/v3/reports/templates/${this.item.id}/duplicate`, { subscription_id: contract_id, user_id: user_id })
      this.$store.commit("reportTemplates/saveReportTemplates", { reportTemplates: this.reportTemplates, params: { contract_id: contract_id } });
      this.reportTemplates[this.contract.id] = await this.$store.dispatch("reportTemplates/fetch", { contract_id: this.contract.id });
      Utils.setLoading.bind(this)(false);

      this.modals.duplicate_template = false;
      if (response.data.data.dashboard_template) {
        Swal.fire(this.$t("general.success"), this.$t(`reports.duplication_success`), "success");
        this.redirectToEdition(response.data.data)
      }
    },
    async deleteTemplate(item) {
      let response = await Swal.fire({
        title: this.$t("user_management.users.alert.are_you_sure"),
        text:  this.$t("reports.confirm_delete_template"),
        icon: "question",
        showCancelButton:  true,
        showConfirmButton: true,
        allowOutsideClick: false
      });
      if (!response.isConfirmed) return;
      Utils.setLoading.bind(this)(true);
      await axios.delete(`/v3/reports/templates/${item.id}`);
      let reportTemplates = Utils.deepClone(this.reportTemplates[this.contract.id]);
      let index = reportTemplates.findIndex(s => s.id == item.id);
      reportTemplates.splice(index, 1);
      this.$store.commit("reportTemplates/saveReportTemplates", { reportTemplates, params: { contract_id: this.contract.id } });
      this.reportTemplates[this.contract.id] = reportTemplates;
      Utils.setLoading.bind(this)(false);
    },
    columns(k) {
      let fields = [
        { [k]: "name",       text: this.$t("reports.template_name"), sortable: true },
        { [k]: "period",     text: this.$t("reports.period"),        sortable: true },
        { [k]: "based_on",   text: this.$t("reports.based_on"),      sortable: true },
        { [k]: "created_at", text: this.$t("reports.created_at"),    sortable: true },
        { [k]: "actions",    text: this.$t("reports.action"),        sortable: true }
      ]
      return fields;
    },
    toggleModal(open) {
      this.modals.new_template = open;
      if (!open) this.newTemplate = { name: "", period: "month" };
    },
    redirectToEdition(template) {
      this.$router.push({ name: "reports_edit_template", params: { id: template.id }, query: { ...this.$route.query } });
    },
    goToPage(to) {
      if (to == "previous")  this.currentPage--;
      else if (to == "next") this.currentPage++;
      else if (to)           this.currentPage = to;
      this.$refs["report_templates"]?.updatePage(this.currentPage);
    },
    formatDate(template) {
      if (!template.created_at) return "-";
      return dayjs(template.created_at).format("DD MMM YYYY - HH:mm");
    }
  },
  computed: {
    user() {
      return this.$store.getters["user/user"]();
    },
    contract() {
      return this.$store.getters["contract/currentContract"];
    },
    filteredTemplates() {
      if (!this.contract?.id || !this.reportTemplates[this.contract.id]) return [];

      let templates    = [...this.reportTemplates[this.contract.id]];
      let searchString = this.filterParams?.data?.search;
      let periods      = this.filterParams.periods;

      if (!searchString?.length && !periods) return templates;

      if (periods?.length) templates = templates.filter(t => periods.includes(t.period));

      if (searchString?.length) {
        let str = searchString.toUpperCase();
        templates = templates.filter(t => (t.name || "").toUpperCase().includes(str));
      }

      // if (templates.length != this.reportTemplates[this.contract.id].length) this.goToPage(1);

      return templates;
    },
    disabledNav() {
      let res = { previous: "", next: "" };
      if (this.currentPage == 1) res.previous = "disabled";
      if (this.currentPage == Math.ceil(this.filteredTemplates.length / this.perPage)) res.next = "disabled";
      return res;
    },
    validFields() {
      return this.newTemplate.name?.length && this.newTemplate.period;
    },
    periods() {
      return this.allPeriods();
    }
  }
}
</script>

<template>
  <div class="w-100">
    <PageHeader :title="$t('reports.report_templates')" :tooltip="$t('reports.templates_help')">
      <template v-slot:subtitle>
        <button class="btn btn-primary" @click="toggleModal(true)">{{ $t("reports.new_template") }}</button>
      </template>
    </PageHeader>

    <Filters @filter:created="saveFilterParams" @filter:change="saveFilterParams" :cols="filterCols" :notifyChange="true" />

    <div class="row">
      <div class="col-xl-12">
        <div style="min-height: 200px;" class="card" :class="{ loading: loading }">
          <div class="card-body">
            <data-table
              ref="report_templates"
              class="thead-light"
              :items="filteredTemplates"
              :headers="columns('value')"
              :rows-per-page="perPage"
              header-text-direction="center"
              body-text-direction="center"
              buttons-pagination
              theme-color="#90a0cb"
              :current-page="currentPage"
              :sort-by="sortBy"
              sort-type="desc"
              hide-footer
            >
              <template #item-period="item">
                {{ periods[item.period] || item.period }}
              </template>

              <template #item-based_on="item">
                {{ $t(`reports.based_on_opts.${item.based_on}`) }}
              </template>

              <template #item-created_at="item">
                {{ formatDate(item) }}
              </template>

              <template #item-actions="item">
                <template v-if="item.dashboard_template_id">
                  <a href="#" @click="redirectToEdition(item)" class="text-nowrap me-3">
                    <i class="mdi mdi-square-edit-outline"></i>
                    {{ $t("reports.edit_report_schedule") }}
                  </a>

                  <a href="#" @click="deleteTemplate(item)" class="text-nowrap">
                    <i class="mdi mdi-trash-can"></i>
                    {{ $t("reports.delete") }}
                  </a>

                  <a href="#" @click="duplicateTemplateCondition(item)" class="text-nowrap ms-3">
                    <i class="mdi mdi-content-duplicate"></i>
                    {{ $t("reports.duplicate") }}
                  </a>
                </template>

                <span v-else>
                  {{ $t("reports.not_editable") }}
                </span>
              </template>
            </data-table>

            <!-- Pagination -->
            <nav class="float-end mt-3" v-if="filteredTemplates.length > perPage">
              <ul class="pagination">
                <li class="page-item" :class="disabledNav.previous"><a class="page-link" href="#" @click="goToPage('previous')">{{ $t("general.previous") }}</a></li>
                <li class="page-item" :class="disabledNav.next"><a class="page-link" href="#" @click="goToPage('next')">{{ $t("general.next") }}</a></li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>

    <b-modal v-model="modals.new_template" @hide="toggleModal(false)" centered hide-footer hide-header size="lg">
      <div class="p-4 d-flex flex-column">
        <h3>{{ $t("reports.template_settings") }}</h3>
        <TemplateSettings :template="newTemplate" class="mt-3" />
        <div class="footer-nav d-flex flex-column flex-sm-row justify-content-between mt-4" style="gap:10px;">
          <button class="btn btn-outline-secondary me-2" @click="toggleModal(false)">{{ $t("surveys_list.cancel") }}</button>
          <button @click="createNewTemplate" class="btn btn-primary me-2" :disabled="!validFields" >{{ $t("reports.save") }}</button>
        </div>
      </div>
    </b-modal>
    <b-modal v-model="modals.duplicate_template" centered hide-footer hide-header size="lg">
      <div class="p-4 d-flex flex-column">
        <h3>{{ $t("reports.template_settings") }}</h3>
        <Duplicate @duplicate='duplicateTemplate' @hide='() => { modals.duplicate_template = false }' :contract='contract' :user='user' />
      </div>
    </b-modal>
  </div>
</template>
