<script>
export default {
  props: ["isBtn", "customClass", "text", "tooltip", "component"],
  computed: {
    computedClass() {
      let def = "btn btn-sm btn-primary";
      if (!this.isBtn) def = "mdi mdi-help-circle font-size-18";
      return this.customClass ? this.customClass : def;
    },
    computedText() {
      return this.text ? "text" : this.$t("selector.documentation");
    },
    computedTooltip() {
      return this.tooltip ? this.tooltip : this.$t("general.documentation_tooltip");
    },
    target() {
      if (this.component) return `documentation-${this.component}`;
      return "documentation-sidebar";
    }
  }
};
</script>

<template>
  <div>
    <button v-if="isBtn" :class="computedClass" data-bs-toggle="offcanvas" :data-bs-target="`#${target}`" :aria-controls="`${target}`" v-b-tooltip.hover :title="computedTooltip">
      {{ computedText }}
    </button>
    <a v-else data-bs-toggle="offcanvas" :href="`#${target}`" :aria-controls="`${target}`" v-b-tooltip.hover :title="computedTooltip">
      <i :class="computedClass"></i>
    </a>
  </div>
</template>
