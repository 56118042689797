import Selector from "./views/selector/index";

import DestinationRoot from "./views/destination/root/index";
import DestinationDashboard from "./views/destination/dashboard/index";
import Ratings from "./views/destination/ratings/index";
import Watchlist from "./views/destination/watchlist/index";
import Insights from "./views/destination/insights/index";
import CustomerFilters from "./views/destination/customised-groups/index";
import SocialSentiment from "./views/destination/social-sentiment/index";
import ExecutiveKPIs from "./views/destination/executive-kpis/index";
import Map from "./views/destination/map/index";
import DestinationUserManagement from "./views/destination/user-management/index";

import FeedbackForm from "./views/feedback-form/index";
import FeedbackRoot from "./views/feedback/root/index";
import Responses from "./views/feedback/responses/index";
import Analytics from "./views/feedback/analytics/index";
import ListSurveys from "./views/feedback/list-surveys/index";
import EditSurvey from "./views/feedback/new-survey/index";
import MailTemplates from "./views/feedback/list-templates/index";
import EditTemplate from "./views/feedback/edit-template/index";
import SendEmail from "./views/feedback/send-email/index";

import ReputationRoot from "./views/reputation/root/index";
import OperationalKPIs from "./views/reputation/operational-kpis/index";
import DailyOperationsRoot from "./views/reputation/daily-operations/index";
import DailyOperationsDashboard from "./views/reputation/daily-operations/dashboard/index";
import DailyOperationsReviews from "./views/reputation/daily-operations/reviews/index";
import DailyOperationsReview from "./views/reputation/daily-operations/review/index";
import DailyOperationsAnalytics from "./views/reputation/daily-operations/analytics/index";
import DailyOperationsPropertyWatchlist from "./views/reputation/daily-operations/property-watchlist/index";
// // import DailyOperationsTrophies from "./views/reputation/daily-operations/trophies/index";

import ReputationUserManagement from "./views/reputation/user-management/index";
import SourceRatings from "./views/reputation/source-ratings/index";
import ReviewResponses from "./views/reputation/review-responses";

// import SentimentPlaygroundRoot from "./views/reputation/sentiment-playground/index";
// import SentimentPlaygroundCreate from "./views/reputation/sentiment-playground/create-sentiment/index";
// import SentimentPlaygroundTest from "./views/reputation/sentiment-playground/test-sentiment/index";

import ReportsRoot from "./views/reports/root/index";
// REPLAB
import RepLabMonthEnd from "./views/reports/replab/month-end/index";
import RepLabWeekly from "./views/reports/replab/weekly/index";
import RepLabEnterpriseMonthly from "./views/reports/replab/enterprise-monthly/index";
import RepLabEnterpriseWeekly from "./views/reports/replab/enterprise-weekly/index";

// GENERAL REPORTS
import ReputationReportSchedules from "./views/reputation/reports/schedules/index";
import ReputationReportDeliveries from "./views/reputation/reports/deliveries/index";
import ListReportTemplates from "./views/reports/templates/list/index";
import EditReportTemplate from "./views/reports/templates/edit/index";
import ShowReportTemplate from "./views/reports/templates/show/index";


import QueriesRoot from "./views/queries/root/index";
import QueriesEditor from "./views/queries/editor/index";

import SettingsRoot from "./views/settings/index";
import PersonalSettingsRoot from "./views/settings/personal-settings/index";
import EmailNotifications from "./views/settings/personal-settings/email-notifications/index";
import UserSettings from "./views/settings/personal-settings/user-settings/index";
import WhiteLabelSettings from "./views/settings/white-label/index";

import Signup from "./views/signup/index";
import AccountsRoot from "./views/accounts/index";
import AccountDetails from "./views/accounts/details";

import DocumentationRoot from "./views/documentation/root/index"
import APIDocumentation  from "./views/documentation/api/index";

let routes = [
  {
    path: "/",
    component: Selector,
  },

  {
    path: "/destination",
    name: "destination",
    component: DestinationRoot,
    meta: {
      name: "destination_root",
      redirect: "/destination"
    },
    children: [
      {
        path: "executive-kpis",
        name: "destination_executive_kpis",
        component: ExecutiveKPIs,
        meta: {
          icon: "mdi mdi-monitor-dashboard",
          extra_permissions: ["destination_fundaments"],
          params: ["tp", "rt"]
        }
      },
      {
        path: "",
        name: "destination_dashboard",
        component: DestinationDashboard,
        meta: {
          icon: "ion ion-md-speedometer",
          extra_permissions: ["destination_fundaments"],
          params: ["sd", "ed", "pt", "cn", "rg", "ct", "cat", "gid", "custom.n"]
        },
      },
      {
        path: "ratings",
        name: "destination_ratings_data",
        component: Ratings,
        meta: {
          icon: "ion ion-md-star",
          extra_permissions: ["destination_fundaments"],
          params: ["sd", "ed", "rt", "pt", "cat", "cn", "rg", "ct", "rcn", "l", "tar", "srcs", "tc", "gid", "b.pt", "b.cat", "b.cnt", "b.cn", "b.rg", "b.ct", "custom.n", "b.rcn", "b.l", "b.tar", "b.srcs", "b.tc", "b.gid", "bt", "be"]
        },
      },
      {
        path: "watchlist",
        name: "destination_watch_list",
        component: Watchlist,
        meta: {
          icon: "ion ion-md-eye",
          params: ["sd", "ed", "pt", "cat", "cn", "rg", "ct", "rcn", "l", "tar", "srcs", "tc", "gid", "custom.n"]
        },
      },
      {
        path: "insights",
        name: "destination_insights",
        component: Insights,
        meta: {
          icon: "ion ion-md-bulb",
          params: ["cn", "rg", "ct", "cid"]
        },
      },
      {
        path: "map",
        name: "destination_map",
        component: Map,
        meta: {
          required_permissions: ["destination_map"],
          icon: "fas fa-fire-alt",
          params: ["sd", "ed", "pt", "cat", "cn", "rg", "ct", "rcn", "l", "tar", "srcs", "tc", "gid", "v", "cl", "dp", "at"],
        },
      },
      {
        path: "customer-groups",
        name: "destination_customer_filters",
        component: CustomerFilters,
        meta: {
          icon: "ion ion-md-settings",
          params: ["pt", "rg", "ct", "s"]
        },
      },
      {
        path: "social-sentiment",
        name: "destination_tci_social_sentiments",
        component: SocialSentiment,
        meta: {
          required_permissions: ["destination_tci_social_sentiments"],
          icon: "ion-md-people",
          params: []
        },
      },
      {
        path: "users",
        name: "destination_users",
        component: DestinationUserManagement,
        meta: {
          icon: "mdi mdi-account-edit",
          required_permissions: ["manager"],
          params: ["gid", "s"]
        }
      },
    ],
  },

  {
    path: "/ck/:key",
    name: "feedback_form",
    component: FeedbackForm,
    meta: {
      unlogged: true,
    }
  },

  {
    path: "/reputation",
    name: "reputation",
    component: ReputationRoot,
    meta: {
      icon: "ion ion-md-speedometer",
      name: "reputation_root",
      redirect: "/reputation"
    },
    children: [
      {
        path: "operational_kpis",
        name: "reputation_operational_kpis",
        component: OperationalKPIs,
        meta: {
          icon: "mdi mdi-monitor-dashboard",
          params: ["gid", "cids", "tp", "rt"]
        },
      },
      {
        path: "",
        name: "reputation_daily_operations",
        component: DailyOperationsRoot,
        meta: {
          icon: "mdi mdi-remote-desktop",
        },
        children: [
          {
            path: "",
            name: "reputation_dashboard",
            component: DailyOperationsDashboard,
            meta: {
              icon: "ion ion-md-speedometer",
              params: ["sd", "ed", "gid", "cids"]
            },
          },
          {
            path: "reviews",
            name: "reputation_reviews",
            component: DailyOperationsReviews,
            meta: {
              icon: "mdi mdi-message-draw",
              params: ["sd", "ed", "gid", "cids", "rcn", "l", "rr", "srcs", "se", "sent", "t", "wr", "tc", "st", "pg", "rid"]
            },
          },
          {
            path: "review",
            name: "reputation_review",
            component: DailyOperationsReview,
            meta: {
              hidden: true,
              params: ["rid", "cid"]
            },
          },
          {
            path: "analytics",
            name: "reputation_analytics",
            component: DailyOperationsAnalytics,
            meta: {
              icon: "mdi mdi-google-analytics",
              params: ["sd", "ed", "rt", "gid", "cids", "rcn", "l", "rr", "srcs", "se", "tc", "c", "b.rcn", "b.l", "b.rr", "b.srcs", "b.se", "b.tc", "st", "svid", "f", "rl", "qt", "sent"]
            },
          },
          {
            path: "property-watchlist",
            name: "reputation_property_watchlist",
            component: DailyOperationsPropertyWatchlist,
            meta: {
              icon: "mdi mdi-eye",
              params: ["sd", "ed", "gid", "cids", "rcn", "l", "rr", "srcs", "se", "tc", "c", "b.rcn", "b.l", "b.rr", "b.srcs", "b.se", "b.tc"]
            },
          },
          {
            path: "review-responses",
            name: "reputation_review-responses",
            component: ReviewResponses,
            meta: {
              icon: "mdi mdi-reply-outline",
              params: ["sd", "ed", "gid", "cids", "srcs", "se"],
            }
          },
          {
            path: "source-ratings",
            name: "reputation_source_ratings",
            component: SourceRatings,
            meta: {
              icon: "mdi mdi-chart-box-outline",
              permissions: ["source_ratings"],
              params: ["sd", "ed", "gid", "cids", "p", "srcs", "se"]
            }
          },
        ]
      },
      {
        path: "users",
        name: "reputation_users",
        component: ReputationUserManagement,
        meta: {
          icon: "mdi mdi-account-edit",
          required_permissions: ["manager"],
          params: ["cids", "ut", "s"]
        }
      },
  //     {
  //       path: "sentiment_playground",
  //       name: "reputation_sentiment_playground",
  //       component: SentimentPlaygroundRoot,
  //       meta: {
  //         icon: "mdi mdi-emoticon-happy-outline",
  //         permissions: ["none"]
  //       },
  //       children: [
  //         {
  //           path: "create",
  //           name: "reputation_sentiment_playground_create",
  //           component: SentimentPlaygroundCreate,
  //           meta: {
  //             icon: "mdi mdi-plus-circle-outline",
  //             permissions: ["admin"]
  //           },
  //         },
  //         {
  //           path: "test",
  //           name: "reputation_sentiment_playground_test",
  //           component: SentimentPlaygroundTest,
  //           meta: {
  //             icon: "mdi mdi-test-tube",
  //             permissions: ["admin"]
  //           },
  //         },
  //       ]
  //     },
    ],
  },

  {
    path: "/feedback",
    name: "feedback",
    component: FeedbackRoot,
    meta: {
      icon: "ion ion-md-speedometer",
      name: "feedback_root",
      redirect: "/feedback/surveys"
    },
    children: [
      {
        path: "surveys",
        name: "feedback_surveys",
        component: ListSurveys,
        alias: '/',
        meta: {
          icon: "ion ion-ios-list-box",
          params: ["cids", "ss", "s"]
        },
      },
      {
        path: "surveys/new",
        name: "feedback_new_survey",
        component: EditSurvey,
        meta: {
          icon: "ion ion-ios-create",
          hidden: true
        },
      },
      {
        path: "surveys/edit/:id",
        name: "feedback_edit_survey",
        component: EditSurvey,
        meta: {
          icon: "ion ion-ios-create",
          hidden: true,
          params: ["tab"]
        },
      },
      {
        path: "responses",
        name: "feedback_responses",
        component: Responses,
        meta: {
          icon: "ion ion-md-list-box ",
          params: ["sd", "ed", "cids", "svid", "f", "rl", "qt", "sent", "pub", "pg"]
        },
      },
      {
        path: "analytics",
        name: "feedback_analytics",
        component: Analytics,
        meta: {
          icon: "ion ion-md-analytics ",
          params: ["sd", "ed", "cids", "svid", "f", "rl", "qt", "sent"]
        },
      },
      {
        path: "mail-templates",
        name: "feedback_mail_templates",
        component: MailTemplates,
        meta: {
          icon: "mdi mdi-mail",
          params: ["cids", "svid", "s", "dt"]
        },
      },
      {
        path: "mail-templates/edit/:id",
        name: "feedback_edit_template",
        component: EditTemplate,
        meta: {
          icon: "ion ion-ios-create",
          hidden: true
        },
      },
      {
        path: "send-email",
        name: "feedback_send_email",
        component: SendEmail,
        meta: {
          icon: "mdi mdi-email-arrow-right",
        },
      }
    ]
  },
  {
    path: "/reports",
    name: "reports",
    component: ReportsRoot,
    meta: {
      icon: "ion ion-md-speedometer",
      name: "reports",
      redirect: "/reports/schedules",
    },
    children: [
      {
        path: "schedules",
        name: "reports_schedules",
        component: ReputationReportSchedules,
        meta: {
          icon: "mdi mdi-file-clock",
          params: ["cids", "rpt", "ps", "s", "uid"]
        },
      },
      {
        path: "deliveries",
        name: "reports_deliveries",
        component: ReputationReportDeliveries,
        meta: {
          icon: "mdi mdi-file-sync",
          params: ["cids", "rpt", "s"]
        },
      },
      {
        path: "templates",
        name: "reports_templates",
        component: ListReportTemplates,
        meta: {
          icon: "mdi mdi-file-document",
          params: ["ps", "s"],
          required_permissions: ["manager"],
        },
      },
      {
        path: "templates/show/:id",
        name: "reports_show_template",
        component: ShowReportTemplate,
        meta: {
          hidden: true,
          escapeParamsVerification: true
        }
      },
      {
        path: "templates/edit/:id",
        name: "reports_edit_template",
        component: EditReportTemplate,
        meta: {
          hidden: true,
          params: ["start_date", "end_date", "company_id", "group_id", "group_ids"]
        }
      },
      {
        path: "replab_month_end",
        name: "replab_month_end",
        component: RepLabMonthEnd,
        meta: {
          hidden: true,
          escapeParamsVerification: true
        }
      },
      {
        path: "replab_weekly",
        name: "replab_weekly",
        component: RepLabWeekly,
        meta: {
          hidden: true,
          escapeParamsVerification: true
        }
      },
      {
        path: "replab_enterprise_monthly",
        name: "replab_enterprise_monthly",
        component: RepLabEnterpriseMonthly,
        meta: {
          hidden: true,
          escapeParamsVerification: true
        }
      },
      {
        path: "replab_enterprise_weekly",
        name: "replab_enterprise_weekly",
        component: RepLabEnterpriseWeekly,
        meta: {
          hidden: true,
          escapeParamsVerification: true
        }
      }
    ],
  },
  {
    path: "/queries",
    component: QueriesRoot,
    meta: {
      icon: "ion ion-md-speedometer",
      name: "queries_root",
      hidden: true,
      redirect: "/queries/editor"
    },
    children: [
      {
        path: "editor",
        name: "queries_editor",
        component: QueriesEditor,
        meta: {
          icon: "ion ion-md-speedometer",
        },
      },
    ],
  },
  {
    path: "/documentation",
    component: DocumentationRoot,
    name: "documentation",
    meta: {
      icon: "ion ion-md-speedometer",
      name: "documentation",
      redirect: "/documentation/api"
    },
    children: [
      {
        path: "api",
        name: "documentation_api",
        component: APIDocumentation,
        meta: {
          icon: "mdi mdi-file-cog-outline"
        }
      }
    ]
  },
  {
    path: "/",
    component: SettingsRoot,
    name: "settings",
    meta: {
      icon: "ion ion-md-speedometer",
      name: "settings",
      unlocked: true,
    },
    children: [
      {
        path: "",
        name: "personal_settings",
        component: PersonalSettingsRoot,
        meta: {
          icon: "mdi mdi-account-cog",
          unlocked: true,
        },
        children: [
          {
            path: "user-settings",
            name: "user_settings",
            component: UserSettings,
            meta: {
              icon: "mdi mdi-account",
              unlocked: true
            },
          },
          {
            path: "email-notifications",
            name: "email_notifications",
            component: EmailNotifications,
            meta: {
              icon: "mdi mdi-email-alert-outline",
              unlocked: true,
              required_context: ["reputation"]
            },
          }
        ]
      },
      {
        path: "white-label",
        name: "white_label",
        component: WhiteLabelSettings,
        meta: {
          icon: "mdi mdi-label",
        }
      },
    ]
  },
  {
    path: "/signup",
    component: Signup,
    name: "signup",
    meta: {
      icon: "mdi mdi-account-plus",
      name: "signup",
      unlogged: true,
    },
  },
  {
    path: "/accounts",
    component: AccountsRoot,
    name: "accounts",
    meta: {
      icon: "ion ion-md-person",
      name: "accounts",
      unlocked: true,
    },
    children: [
      {
        path: '',
        name: "details",
        meta: {
          icon: "mdi mdi-account-details",
          name: "details",
          unlocked: true,
        },
        component: AccountDetails,
      },
    ]
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/"
  }
]

export default routes

