<script>
import axios from 'axios';
import Utils from "@/js/utils";
import DataUtils     from "@/js/data-utils";
import OleryTable    from "@/components/olery-table";
import TopicSelector from "@/components/topic-selector";

export default {
  data() {
    return {
      data: {},
      rows: [],
      loading: true,
      perPage: 50,
      currentPage: 1,
      truth: true,
      promisesId: null,
      scores: [],
      ratings: [],
      baseScoresOptions: ["sustainable_travel", "sanitary_safety", "covid"],
      scoresOptions: [],
      ratingsOptions: ["overall", "fnb", "service", "cleanliness", "facilities", "value", "location"]
    };
  },
  props: ["type", "filterParams"],
  components: { OleryTable, TopicSelector },
  watch: {
    filterParams: async function () {
      await this.loadTopics();
      this.loadListener(this.filterParams);
    }
  },
  methods: {
    ...DataUtils,
    loadListener(params) {
      this.promisesId = parseInt(Math.random() * 100);
      let p = {
        own: { ...params.data, segment: "hotel_id", source: params.data?.sources },
        competitors: { ...params.benchmark, segment: "hotel_id", source: params.benchmark?.sources }
      }
      if (!this.isReputation) this.load(p.own, this.promisesId);
      this.loadReputation(p, [...(params.groups_companies || []), ...(params.competitors || [])], this.promisesId);
    },
    async load(params, promisesId) {
      Utils.setLoading.bind(this)(true)
      this.data = {}
      let topRanking;
      var sentiment, row, t, promises = [];
      promises.push(axios.get('/v3/destination/stats', { params }).then(response => {
        if (promisesId != this.promisesId) return;
        response.data.data.forEach((r,i) => {
          row = this.data[r.hotel_id] || {}
          row['ranking'] = topRanking = i + 1
          row['id']             = r.hotel_id
          row['name']           = r.name
          row['review_count']   = r.stats.review_count
          row['overall']        = this.round(r.stats.overall_rating_average / 10);
          row['orig_overall']   = r.stats.overall_rating_average / 10
          this.data[r.hotel_id] = row
        })
      }))
      promises.push(axios.get('/v3/destination/sentiment', { params }).then(response => {
        response.data.data.forEach(s => {
          if (promisesId != this.promisesId) return;
          row = this.data[s.hotel_id] || { name: s.name, id: s.hotel_id }
          sentiment = s.sentiment.find(o => o.topic == 'overall')
          row['opinions_count']        = sentiment.opinions_count
          row['sentiment_rating']      = this.round(sentiment.sentiment_score);
          row['orig_sentiment_rating'] = sentiment.sentiment_score
          row['sentiment_count'] = sentiment.review_count


          t = s.sentiment.find(t => t.topic == 'sustainable_travel')
          if (t) row['st_score'] = this.round(t.sentiment_score);

          t = s.sentiment.find(t => t.topic == 'sanitary_safety')
          if (t) row['ss_score'] = this.round(t.sentiment_score);

          t = s.sentiment.find(t => t.topic == 'covid')
          if (t) row['covid'] = t.opinions_count;

          this.data[s.hotel_id]   = row
        })
      }));
      promises.push(axios.get('/v3/destination/ratings', { params }).then(response => {
        response.data.data.forEach(s => {
          if (promisesId != this.promisesId) return;
          row = this.data[s.hotel_id] || { name: s.name, id: s.hotel_id }

          this.ratingsOptions.forEach(rating => {
            t = s.ratings?.find(t => t.topic == rating);
            if (t) row[t.topic] = this.round(t.value / 10);
          });

          this.data[s.hotel_id] = row
        })
      }));

      await Promise.all(promises)
      this.createRows(topRanking)
      Utils.setLoading.bind(this)(false)
    },
    async loadReputation(p, companies, promisesId) {
      Utils.setLoading.bind(this)(true);
      this.data = {};
      this.rows = [];
      let paramsOwn = Utils.slice(p.own, ["start_date", "end_date", "reviewer_country", "languages", "sources", "travel_composition", "packages"]);
      paramsOwn = { ...paramsOwn, ...Utils.slice(p.own, ["countries", "languages", "sources", "compositions", "packages"].map(k => `exclude_${k}`)) }
      let paramsCompetitors = {
        start_date: paramsOwn.start_date, end_date: paramsOwn.end_date,
        ...Utils.slice(p.competitors, ["reviewer_country", "languages", "sources", "travel_composition", "packages"]),
        ...Utils.slice(p.competitors, ["countries", "languages", "sources", "compositions", "packages"].map(k => `exclude_${k}`))
      };

      let sentiment, row, t, promises = [];
      await Utils.asyncForEach(companies, async company => {
        let params = paramsOwn;
        if (company.competitor) params = paramsCompetitors;
        promises.push(axios.get(`/v3/companies/${company.id}/stats`, { params }).then((response) => {
          if (promisesId != this.promisesId) return;
          let data = response.data.data;
          row = this.data[company.id] || {};
          row['id']             = company.id;
          row['name']           = company.name;
          row['review_count']   = data.review_count;
          row['overall']        = this.round(data.overall_rating_average / 10);
          row['orig_overall'] = data.overall_rating_average / 10;
          this.data[company.id] = row;
        }));

        promises.push(axios.get(`v3/companies/${company.id}/sentiment`, { params }).then((response) => {
          if (promisesId != this.promisesId) return;
          let data = response.data.data;
          row = this.data[company.id] || {};
          sentiment = data?.find(o => o.topic == 'overall');
          row['opinions_count']   = sentiment?.opinions_count;
          row['sentiment_rating'] = this.round(sentiment?.sentiment_score);
          row['orig_sentiment_rating'] = sentiment?.sentiment_score;
          row['sentiment_count'] = sentiment?.review_count;

          t = data?.find(t => t.topic == 'health_precautions');
          if (t) row['pp_score'] = this.round(t.sentiment_score);

          t = data?.find(t => t.topic == 'sanitary_safety');
          if (t) row['ss_score'] = this.round(t.sentiment_score);

          t = data?.find(t => t.topic == 'covid');
          if (t) row['covid'] = t.opinions_count;

          this.data[company.id]   = row;
        }));

        promises.push(axios.get(`v3/companies/${company.id}/ratings`, { params }).then((response) => {
          if (promisesId != this.promisesId) return;
          let data = response.data.data;
          row = this.data[company.id] || {};

          this.ratingsOptions.forEach(rating => {
            t = data?.find(t => t.topic == rating);
            if (t) row[t.topic] = this.round(t.value / 10);
          });

          this.data[company.id] = row;
        }));

        await Promise.all(promises);
        promises = [];
        this.createRows();
        Utils.setLoading.bind(this)(false);
      })

      this.rows.sort((a, b) => {
        if (b.review_count == null) return -1;
        if (a.review_count == null) return  1;
        return b.review_count - a.review_count
      });
      this.rows.forEach((row, index) => row["ranking"] = index + 1);
    },
    createRows(topRanking = 0) {
      this.rows = []
      var r
      Object.keys(this.data).forEach(hotel_id => {
        r = this.data[hotel_id]
        if (!r['ranking']) r['ranking'] = ++topRanking
        this.columns.map(col => {
          if (r[col.value] == undefined) r[col.value] = null
          if (!isNaN(r[col.value]) && r[col.value] != null) r[col.value] = Number(r[col.value])
        })
        r['tes_score'] = Utils.calcTES(r.orig_overall, r.review_count, r.orig_sentiment_rating, r.sentiment_count)
        this.rows.push(r)
      })
    },
    scoreClass(r, k) {
      if (['overall', 'sentiment_rating', 'tes_score', 'st_score', 'ss_score'].includes(k))
        return window.oly.utils.ratings.toCss(r[k])
      else
        return k
    },
    async loadTopics() {
      const topics = await this.$store.dispatch("topics/fetch");
      const byParent = this.topicsByParent(topics);
      const scores   = (byParent.find(p => p.key == "scores")?.children || []).map(s => s.topic);
      this.scoresOptions = this.baseScoresOptions.filter(s => scores.includes(s));
    },
    round(num) {
      return Number(Utils.round(num));
    }
  },
  computed: {
    isReputation() {
      return this.type == "reputation";
    },
    fileName() {
      return this.$t("general.export_file_name", {
        title: this.$t("watchlist.title"),
        sd: this.filterParams?.data?.start_date,
        ed: this.filterParams?.data?.end_date
      });
    },
    columns() {
      let res = [
        { value: 'ranking',          text: '',                                              sortable: true, type: "number" },
        { value: 'name',             text: this.$t('watchlist.property'),                   sortable: true, type: "text"   },
        { value: 'review_count',     text: this.$t('watchlist.watchlist.review_count'),     sortable: true, type: "number" },
        { value: 'opinions_count',   text: this.$t('watchlist.watchlist.opinions_count'),   sortable: true, type: "number" },
        { value: 'tes_score',        text: this.$t('watchlist.watchlist.tes_score'),        sortable: true, type: "number" },
        { value: 'sentiment_rating', text: this.$t('watchlist.watchlist.sentiment_rating'), sortable: true, type: "number" }
      ]
      if (this.scores.includes("sustainable_travel")) res.push({ value: 'st_score', text: this.$t('watchlist.watchlist.st_score'), sortable: true, type: "number" })
      if (this.scores.includes("sanitary_safety")) res.push({ value: 'ss_score', text: this.$t('watchlist.watchlist.ss_score'), sortable: true, type: "number" })
      if (this.scores.includes("covid")) res.push({ value: 'covid', text: this.$t('watchlist.watchlist.covid_mentions'), sortable: true, type: "number" })

      this.ratingsOptions.forEach(topic => {
        if (this.ratings.includes(topic)) res.push({
          value: topic,
          text: topic != "overall" ? this.$t(`ratings.rating_comparison.kpis.${topic}`) : this.$t('watchlist.watchlist.overall'),
          sortable: true,
          type: "number"
        });
      });

      return res;
    },
    scoreCols() {
      return ["sentiment_rating", "tes_score", "st_score", "ss_score"].concat(this.ratings);
    }
  }
};
</script>

<template>
  <div style='min-height: 200px;' class="card" :class="{'loading': loading}">
    <div class="card-body">
      <OleryTable
        :loading="loading"
        :title="$t('watchlist.title')"
        :fileName="fileName"
        titleClass="m-0 font-size-21"
        :tooltip="$t('watchlist.help')"
        :btnText="$t('general.export_excel')" btnClass="btn btn-secondary text-nowrap"
        :ratingCols="scoreCols"
        :dataTable="{
          tableHeaderClass:    'thead-light',
          items:               rows,
          headers:             columns,
          headerTextDirection: 'center',
          bodyTextDirection:   'center',
          sortBy:              'review_count',
          sortType:            'desc',
          pagination:          true
        }"
      >
        <template v-slot:below-title>
          <div class="mt-3">
            <TopicSelector v-model="scores"  type="scores"  :options="[...new Set(scoresOptions)]"  />
            <TopicSelector v-model="ratings" type="ratings" :options="ratingsOptions" />
          </div>
        </template>
      </OleryTable>
    </div>
  </div>
</template>
