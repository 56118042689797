<script>
export default {
  data() {
    return {};
  },
};
</script>

<template>
    <router-view></router-view>
</template>