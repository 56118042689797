<script>
export default {
  data() {
    return {
      documentation: {},
      loading: true,
      minWidth: 200,
      width: 400,
      title: "",
      content: "",
      dragging: false
    };
  },
  props: ["component"],
  inject: ["hasDocumentation"],
  created() {
    if (this.component) this.getDocumentation(this.component);
  },
  methods: {
    async getDocumentation(id) {
      this.loading = true;
      this.documentation = await this.$store.dispatch("documentation/fetch", { slug: id });
      this.content = this.formatContent();
      this.title   = this.documentation.title?.rendered;
      this.hasDocumentation[this.routeName] = !!this.documentation?.id;
      this.loading = false;
    },
    formatContent() {
      if (!this.documentation.content) return;
      const content = this.documentation.content?.rendered || "";
      return content.replace(/<img[^>]+>/g, img => {
        return img.replace("<img", `<img style="max-width: 100%;"`);
      });
    },
    startResize() {
      this.dragging = true;
      window.addEventListener("mousemove", this.onMouseMove);
      window.addEventListener("mouseup", this.stopResize);
    },
    onMouseMove(e) {
      if (!this.dragging) return;
      this.width = Math.max(this.minWidth, e.clientX);
    },
    stopResize() {
      this.dragging = false;
      window.removeEventListener("mousemove", this.onMouseMove);
      window.removeEventListener("mouseup", this.stopResize);
    },
  },
  watch: {
    routeName: {
      handler: function() {
        if (!this.component && this.routeName) this.getDocumentation(this.routeName);
      },
      immediate: true
    }
  },
  computed: {
    routeName() {
      return this.$route.name;
    },
    computedWidth() {
      return `width: ${this.width}px;`;
    },
    computedId() {
      if (this.component) return `documentation-${this.component}`;
      return "documentation-sidebar";
    }
  }
};
</script>

<template>
  <div v-if="documentation && documentation.id">
    <div class="offcanvas offcanvas-start" data-bs-backdrop="false" :id="computedId" data-bs-scroll="true" tabindex="-1" :style="computedWidth">
      <div v-if="loading" class="loading h-100"></div>
      <template v-else>
        <div class="offcanvas-header d-flex align-items-center justify-content-between p-3 overflow-hidden">
          <h4 class="m-0">{{ title }}</h4>
          <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas"></button>
        </div>

        <div class="offcanvas-body pb-0 pt-1 px-3">
          <div v-html="content"></div>
        </div>
      </template>
      <div class="resizer" @mousedown="startResize" :style="`left:${width}px;`"></div>
    </div>
  </div>
</template>
