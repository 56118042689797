<script>
import quarterOfYear  from "dayjs/plugin/quarterOfYear";
import PageHeader     from "@/components/page-header";
import TopicSelector  from "@/components/topic-selector";
import Filters        from "@/components/filter";
import OperationalKPI from "./operational-kpi";
import Utils          from "@/js/utils";
import axios          from "axios";
import dayjs          from "dayjs";
import DataUtils      from "@/js/data-utils";

dayjs.extend(quarterOfYear);

export default {
  data() {
    return {
      loading: false,
			filterCols:   ["group_companies", "companies", "time_period", "rating_type"],
      timePeriods: { month: { default: false }, quarter: { default: true }, year: { default: false } },
      params:       { timePeriod: "quarter", ratingType: "numerical", startDate: null, endDate: null, previousStart: null, previousEnd: null },
      contextNames: { data: "main", benchmark: "benchmark" },
      ratings: [],
      scores: [],
      baseScores: ["sustainable_travel", "sanitary_safety", "health_precautions"],
      baseRatings: ["overall", "fnb", "service", "cleanliness", "facilities", "value", "location"],
      kpis: {
        ratings: [],
        scores:  [],
        top_segments: [
          { key: "top_nationalities",  data: [], segment: "country"     },
          { key: "top_compositions",   data: [], segment: "composition" },
          { key: "top_sources",        data: [], segment: "source"      },
        ],
      },
      filterParams: {},
      valueFields: {
        sentiment: "sentiment_score",
        numerical: "value",
        tes:       "score"
      }
    };
  },
  components: { PageHeader, Filters, OperationalKPI, TopicSelector },
  created() {
    this.loadTopics();
  },
  computed: {
    subscription() {
      return this.$store.getters["contract/currentContract"];
    },
    filterTitle () {
      let filters = this.subscription.filters
      let places  = {}
      let levels  = ["city", "regions", "country", "continent"];
      if (!filters || !filters["main"]) return "";

      Object.values(this.contextNames).map(block => {
        if (filters[block]) {
          ["*", "executive_kpis"].forEach(context => {
            if (filters[block][context])
              places[block] = levels.map(l => filters[block][context][l]).filter(n => n).join(" / ")
          })
        }
      })
      return places
    },
    showSection() {
      if (!this.loading) {
        return Object.keys(this.kpis).reduce((res, section) => {
          let hasValues = this.kpis[section].reduce((r, a) => {
            if (section != "top_segments") return r + Object.keys(a.data?.current).length + Object.keys(a.data?.previous).length;
            else return r + a.data?.length;
          }, 0);
          return { ...res, [section]: !!hasValues };
        }, {});
      } else return {};
    }
  },
  methods: {
    ...DataUtils,
    load(params) {
      this.params.ratingType = params.data.rating_type || "tes";
      this.params.timePeriod = Array.isArray(params.data.time_period) ? params.data.time_period[0] : params.data.time_period || "quarter";
      this.params.startDate  = dayjs().add(-1, this.params.timePeriod).startOf(this.params.timePeriod).format("YYYY-MM-DD")
      this.params.endDate    = dayjs().add(-1, this.params.timePeriod).endOf(this.params.timePeriod).format("YYYY-MM-DD");
      this.params.previousStart    = dayjs(this.params.startDate).add(-1, "year").format("YYYY-MM-DD")
      this.params.previousEnd      = dayjs(this.params.endDate).add(-1, "year").format("YYYY-MM-DD");
      this.params.company_ids = params.data.company_ids;
      this.params.group_ids   = params.data.group_ids;
      this.loadListener();
    },
    async loadListener() {
      Utils.setLoading.bind(this)(true);
      this.clearData();
      let p = {
        period:           this.params.timePeriod,
        start_date:       this.params.startDate,
        end_date:         this.params.endDate,
        subscription_ids: this.subscription.id,
        company_ids:      this.params.company_ids,
        group_ids:        this.params.group_ids
      };
      let rt = this.params.ratingType == "numerical" ? "ratings" : this.params.ratingType;
      let promises = [];
      ["data", "benchmark"].forEach(block => {
        promises.push(axios.get(`/v3/${rt}/kpi`, { params: { ...p, competitors: block == "benchmark" } }).then(response => this.saveData(response.data.data, this.params.ratingType, block)));
      });

      await Promise.all(promises);

      // top segments
      promises = [];
      let valueField = this.params.ratingType == "sentiment" ? "opinions" : rt;
      this.kpis.top_segments.forEach(segment => {
        // current
        promises.push(axios.get(`/v3/${rt}/stats`, { params: { ...p, segment: segment.segment } }).then(response => {
          let data = response.data.data.map(r => ({ ...r, ...r[valueField]?.find(s => s.topic == "overall") }));

          data.forEach(item => {
            let found = segment.data.find(s => s.key == item.segment);
            if (found) found.current = { ...found.current, [this.params.ratingType]: item };
            else segment.data.push({ key: item.segment, title: item.segment_title, current: { [this.params.ratingType]: item } });
          });
        }));

        // previous
        promises.push(axios.get(`/v3/${rt}/stats`, { params: { ...p, start_date: this.params.previousStart, end_date: this.params.previousEnd, segment: segment.segment } }).then(response => {
          let data = response.data.data.map(r => ({ ...r, ...r[valueField]?.find(s => s.topic == "overall") }));

          data.forEach(item => {
            let found = segment.data.find(s => s.key == item.segment);
            if (found) found.previous = { ...found.previous, [this.params.ratingType]: item };
            else segment.data.push({ key: item.segment, title: item.segment_title, previous: { [this.params.ratingType]: item } });
          });
        }));
      });

      await Promise.all(promises);

      this.calculateTopSegmentsTrends();

      Utils.setLoading.bind(this)(false);
		},
    saveData(data, ratingType, block) {
      ["scores", "ratings"].forEach(field => {
        this.kpis[field].forEach(kpi => {
          if (data[kpi.key]) {
            let current  = data[kpi.key].current.value  || 0;
            let previous = data[kpi.key].previous.value || 0;
            let trend    = null;
            if ((current || current == 0) && previous) trend = ((current - previous) / previous) * 100;

            kpi[block].current[ratingType]  = {
              review_count: data[kpi.key].current.review_count,
              value: Utils.round(current > 10 ? current / 10 : current),
              trend: trend
            };
            kpi[block].previous[ratingType] = {
              review_count: data[kpi.key].previous.review_count,
              value: Utils.round(previous > 10 ? previous / 10 : previous)
            };
          }
        });
      });
    },
    calculateTopSegmentsTrends() {
      let valueField = this.valueFields[this.params.ratingType];
      this.kpis.top_segments.forEach(segment => {
        segment.data.forEach(item => {
          let current  = item.current?.[this.params.ratingType]?.[valueField];
          let previous = item.previous?.[this.params.ratingType]?.[valueField];
          let trend    = null;
          if ((current || current == 0) && previous) {
            trend = ((current - previous) / previous) * 100;
            item.current[this.params.ratingType].trend = trend;
          }
        })
      });
    },
    clearData() {
      ["scores", "ratings"].forEach(field => {
        this.kpis[field].forEach(kpi => {
          kpi.data = { current: {}, previous: {} };
          kpi.benchmark = { current: {}, previous: {} };
        });
      });
      this.kpis.top_segments.forEach(kpi => {
        kpi.data = [];
      });
    },
    showKPI(section, key) {
      if (/scores|ratings/.test(section)) return this[section].includes(key);
      return true;
    },
    async loadTopics() {
      return this.$store.dispatch("topics/fetch").then(topics => {
        const baseObj  = { current: {}, previous: {} };
        const byParent = this.topicsByParent(topics);
        const scores   = (byParent.find(p => p.key == "scores")?.children || []).map(s => s.topic);
        this.kpis.scores  = this.baseScores.filter(s => scores.includes(s)).map(s => ({ key: s, data: baseObj, benchmark: baseObj }));
        this.kpis.ratings = this.baseRatings.filter(r => byParent.find(b => b.key == r)).map(s => ({ key: s, data: baseObj, benchmark: baseObj }));
        this.kpis.ratings.unshift({ key: "overall", data: baseObj, benchmark: baseObj });
      });
    },
	},
  watch: {
    filterParams: function () {
      this.load(this.filterParams);
    },
    subscription: function () {
      if (this.subscription && this.params.startDate) this.loadListener();
    }
  }
};
</script>

<template>
  <div>
    <PageHeader :title="$t('operational_kpis.title')" />

    <div class="row">
      <div class="col-lg-12">
        <Filters @filter:created="saveFilterParams" @filter:update="saveFilterParams" :cols="filterCols" :preventSubmit="true" :timePeriods="timePeriods" />

        <div v-if="filterParams.data && !loading" class="row">
          <template v-for="kpisArray, section in kpis">
            <div v-if="showSection[section]" class="col-12" :key="section">
              <h3 class="mb-3 mt-4">{{ $t("executive_kpis.titles."+section) }}</h3>

              <TopicSelector v-if="section == 'ratings'" v-model="ratings" type="ratings" :options="kpis.ratings.map(s => s.key)" />
              <TopicSelector v-if="section == 'scores'" v-model="scores" type="scores" :options="kpis.scores.map(s => s.key)" />

              <div class="row">
                <template v-for="kpi in kpisArray" :key="kpi.key">
                  <OperationalKPI v-if="showKPI(section, kpi.key)" dashboardType="reputation" :ratingType="params.ratingType" :Kpi="kpi.key" :data="kpi" :period="params.timePeriod" />
                </template>
              </div>
            </div>
          </template>
        </div>

        <div v-if="loading" class="card loading" style="height: 200px;"></div>
        <div v-if="!filterParams.data" class="card bg-light select-group-company">{{ $t("general.select_group_or_company") }}</div>
      </div>
    </div>
	</div>
</template>
