<script>
import axios     from 'axios';
import Utils     from "@/js/utils";
import NotLoaded from "@/components/not-loaded";
import Loading   from "@/components/loading";

export default {
  props: ["settings", "start_date", "end_date", "limit", 'contract', 'company_id'],
  components: { NotLoaded, Loading },
  data() {
    return {
      loading:   false,
      error:     false,
      tableData: [],
      reports:   window.reports,
      utils:     Utils
    }
  },
  created() {
    this.getData()
  },
  methods: {
    overPeriod(data) {
      return data[3].current.overall_rating / data[3].yoy.overall_rating
    },
    getColorDifferenceColor(currentValue, yoyValue) {
      const difference = currentValue - yoyValue;
      return difference < 0 ? 'red' : 'green';
    },
    async getData() {
      if(!this.company_id) return console.warn('Missing company_id for table - Benchmark own groups')

      this.loading = true
      this.error = false
      axios.get("/v3/query/db/_", {
        params: {
          identifier: 'generic',
          row: 'company_groups',
          periods: {
            yoy: 1
          },
          company_id: this.company_id,
          group_ids:  this.settings.bench_group_ids,
          start_date: this.start_date,
          end_date: this.end_date,
          limit: this.limit,
          subscription_id: this.contract.id
        }
      }).then(response => {
        this.tableData = response.data.data?.slice(1).map(d => {
          d[3].reviews_color  = d[3].current?.reviews - d[3].yoy?.reviews < 0 ? 'red' : 'green'
          d[3].rating_color   = (d[3].current?.overall_rating - d[3].yoy?.overall_rating) < 0 ? 'red' : 'green';
          ['current', 'yoy'].forEach(k => {
            if (d[3][k]) {
              d[3][k].overall_rating = this.utils.round(d[3][k].overall_rating / 10)
              d[3][k].avg_reviews    = this.utils.round(d[3][k].reviews / d[3][k].properties)
            }
          })
          d[3].rating_change  = this.reports.percentDelta(d[3].current?.overall_rating, d[3].yoy?.overall_rating);
          d[3].reviews_change = this.reports.percentDelta(d[3].current?.avg_reviews, d[3].yoy?.avg_reviews);

          return d
        })
        this.loading = false
      }).catch((e) => {
        this.error = true
        this.loading = false
        throw e
      })
    },
  },
}
</script>
<template>
  <div>
    <table class="table table-sm" v-if="tableData && tableData.length">
      <thead class="thead-primary">
        <tr>
          <th class="text-left" >{{ $t("reports.name") }}</th>
          <th class="text-center" >{{ settings.labels?.numerical_rating || $t("reports.table.numerical_rating") }}</th>
          <th class="text-center" >{{ settings.labels?.trend || $t("reports.settings_modal.trend") }}</th>
          <th class="text-center" >{{ settings.labels?.reviews_count || $t("reports.table.reviews_count") }}</th>
          <th class="text-center" >{{ settings.labels?.trend || $t("reports.settings_modal.trend") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr class="text-center" v-for="row in tableData" :key="row[0]">
          <td class="text-left">{{ row[1] }}</td>
          <td>
            {{ utils.round(row[3].current?.overall_rating) }}
          </td>
          <td>
            {{ row[3].yoy?.overall_rating }}
            <span :style="{ color: row[3].rating_color }" >
              {{ row[3].rating_change }}
            </span>
          </td>
          <td>
            {{ utils.round(row[3].current?.reviews / row[3].current?.properties, 0) }}
          </td>
          <td>
            {{ row[3].yoy?.avg_reviews }}
            <span :style="{ color: row[3].reviews_color }" >
              {{ row[3].reviews_change }}
            </span>
          </td>
        </tr>
      </tbody>
    </table>

    <Loading v-else-if="loading" />
    <NotLoaded :data="tableData" :error="error" v-else-if="!loading" />
  </div>
</template>
