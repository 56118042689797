<script>
import Reports   from "@/js/reports";
import NotLoaded from "@/components/not-loaded";
import Loading   from "@/components/loading";

export default {
  data() {
    return {
      loading: true,
      error: false,
      tableData: [],
      reports: window.reports,
    }
  },
  props: ['group_ids', 'group_id', 'start_date', 'end_date', 'settings'],
  components: { NotLoaded, Loading },
  methods: {
    ...Reports.requests,
    async load() {
      try {
        this.loading = true
        this.error = false
        if (!this.settings.use_custom_benchmark)
          this.settings.bench_group_ids = [ ...new Set(this.group_ids)]

        // remove group_id as it is already being added
        const i = this.settings.bench_group_ids.indexOf(this.group_id.toString())
        if (i >= 0) this.settings.bench_group_ids.splice(i, 1)

        let r          = await this.getReports("groups_reviews_benchmark")
        this.tableData = r?.slice(1)
      } catch {
        this.loading = false
        this.error = true
      }
    },
    percentFormat(v) {
      return (v * 100).toFixed(2) + '%';
    },
  },
  async mounted() {
    this.load()
  }
}
</script>
<template>
  <div>
    <table class="table table-sm" v-if="tableData && tableData.length">
      <thead>
        <tr class='text-center'>
          <th class='text-left text-capitalize'>{{ $t('reports.table.destination') }}</th>
          <th>{{ settings.labels?.reviews_quarter || $t('reports.table.reviews_quarter') }}</th>
          <th>{{ settings.labels?.per_property || $t('reports.table.per_property') }}</th>
          <th>{{ settings.labels?.year_over_year || $t('reports.table.year_over_year') }}</th>
          <th>{{ settings.labels?.last_12_months || $t('reports.table.last_12_months') }}</th>
          <th>{{ settings.labels?.per_property || $t('reports.table.per_property') }}</th>
          <th>{{ settings.labels?.previous_12_months || $t('reports.table.previous_12_months') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr class='text-center' v-for="row in tableData" :key="row[2]">
          <td scope="row" class='text-left text-capitalize'>{{ row[2] }}</td>
          <td>{{ reports.format(row[3]) }}</td>
          <td>{{ Math.round(row[4]) }}</td>
          <td :style="{ color: row[3] - row[5] < 0 ? 'red' : 'green' }" class="right-bordered-cell">
            {{ reports.percentDelta(row[3], row[5]) }}
          </td>
          <td>{{ reports.format(row[6]) }}</td>
          <td>{{ Math.round(row[7]) }}</td>
          <td :style="{ color: row[6] - row[8] < 0 ? 'red' : 'green' }">
            {{ reports.percentDelta(row[6], row[8]) }}
          </td>
        </tr>
      </tbody>
    </table>

    <Loading v-else-if="loading" />
    <NotLoaded :data="tableData" :error="error" v-else-if="!loading" />
  </div>
</template>
