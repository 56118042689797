import router from "@/router/index";
import Utils  from "@/js/utils";

export const state = {
  currentContract: null,
  contracts:       [],
  excludedRedirection: ["white-label", "user-settings", "email-notifications", "queries", "signup", "documentation", "reports"]
};
export const getters = {
  contracts:       (state) => state.contracts,
  currentContract: (state) => state.currentContract
};
export const mutations = {
  saveContract(state, { contract }) {
    state.currentContract = contract;
  },
  saveContracts(state, { contracts }) {
    state.contracts = contracts;
  },
  updateContract(state, { contract }) {
    state.currentContract = contract;
    state.contracts = [ ...state.contracts.map(c => c.id != contract.id ? c : contract) ];
  }
};

export const actions = {
  setContracts({ commit, dispatch }, user) {
    let contracts = Utils.products.reduce((res, ctx) => {
      user[`${ctx}_contracts`].forEach(contract => {
        let find = res.find(c => c.id == contract.id);
        if (find) find.products.push(ctx);
        else res.push({ ...contract, products: [ctx] });
      });
      return res;
    }, []);
    contracts = [...new Map(contracts.map(c => [c.id, c])).values()];
    commit("saveContracts", { contracts });

    let context = router.currentRoute.value.path.split("/")[1];
    let contract;

    // try to set contract from URL
    let sid = router.currentRoute.value.query.sid;
    if (sid) {
      contract = contracts.find(c => c.id == sid);
      if (contract) return dispatch("setContract", contract);
      else if (user.admin) return dispatch("setContract", { id: sid, products: Utils.products });
    }

    // try to set contract from localStorage, if contract has access to current page
    sid = JSON.parse(window.localStorage.getItem("sid"));
    const pageContracts = contracts.filter(c => Utils.permissions.can(router.currentRoute.value, context, c, user));

    if (sid) contract = pageContracts.find(c => c.id == sid);

    if (!contract) {
      if (pageContracts.length) contract = pageContracts[0];
      else if (user.admin) contract = { id: sid, products: Utils.products };

      // try to set the first contract for the current product context
      else if (!Utils.products.includes(context)) contract = contracts[0];
      else contract = contracts.find(c => c.products.includes(context));

      if (!contract) contract = contracts[0];
    }

    if (contract) dispatch("setContract", contract);
  },
  async setContract({ commit }, contract) {
    let path  = router.currentRoute.value.path;
    let query = router.currentRoute.value.query;
    let hash  = router.currentRoute.value.hash;

    if (!contract) return router.push({ path: "/", query, hash }); // in the case that there is no contract for the user

    await router.push({ path, query: { ...query, sid: contract.id }, hash });
    commit("saveContract", { contract });
    window.localStorage.setItem("sid", JSON.stringify(contract?.id));
  }
};
