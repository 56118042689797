<script>
import ratings   from '@/js/ratings'
import Utils     from '@/js/utils'
import Reports   from "@/js/reports";
import NotLoaded from "@/components/not-loaded";
import Loading   from "@/components/loading";

export default {
  data() {
    return {
      loading: true,
      error: false,
      tableData: [],
      ratings: ratings,
      Utils: Utils,
      reports: Reports,
    }
  },
  props: ['group_id', 'group_ids', 'start_date', 'end_date', 'settings'],
  components: { NotLoaded, Loading },
  methods: {
    ...Reports.requests,
    async load() {
      try {
        this.loading = true
        this.error = false
        if (!this.settings.use_custom_benchmark)
          this.settings.bench_group_ids = [ ...new Set(this.group_ids)]

        // remove group_id as it is already being added
        const i = this.settings.bench_group_ids.indexOf(this.group_id.toString())
        if (i >= 0) this.settings.bench_group_ids.splice(i, 1)

        let r          = await this.getReports("groups_ratings_benchmark")
        this.tableData = r?.slice(1)
      } catch {
        this.loading = false
        this.error = true
      }
    }
  },
  async mounted() {
    this.load()
  }
}
</script>
<template>
  <div>
    <table class="table table-xs" v-if="tableData && tableData.length">
      <thead>
        <tr class="text-center">
          <th class="text-left text-capitalize">{{ $t('reports.table.destination') }}</th>
          <th>{{ settings.labels?.rating_quarter || $t('reports.table.rating_quarter') }}</th>
          <th>{{ settings.labels?.year_over_year || $t('reports.table.year_over_year') }}</th>
          <th>{{ settings.labels?.last_12_months || $t('reports.table.last_12_months') }}</th>
          <th>{{ settings.labels?.previous_12_months || $t('reports.table.previous_12_months') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr class="text-center" v-for="row in tableData" :key="row[2]">
          <td scope="row" class='text-left text-capitalize'>{{ row[2] }}</td>
          <td :class="Utils.ratings.toCss100(row[3])">{{ ratings.format(row[3] / 10) }}</td>
          <td class="border" :style="{ color: row[3] - row[4] < 0 ? 'red' : 'green' }">{{ reports.percentDelta(row[3],
            row[4]) }}</td>
          <td :class="Utils.ratings.toCss100(row[3])">{{ ratings.format(row[5] / 10) }}</td>
          <td :style="{ color: row[5] - row[6] < 0 ? 'red' : 'green' }">{{ reports.percentDelta(row[5], row[6]) }}</td>
        </tr>
      </tbody>
    </table>

    <Loading v-else-if="loading" />
    <NotLoaded :data="tableData" :error="error" v-else-if="!loading" />
  </div>
</template>
