<script>
import axios         from "axios";
import Utils         from "@/js/utils";
import Multiselect   from "vue-multiselect";
import OleryTable    from "@/components/olery-table";
import TopicSelector from "@/components/topic-selector";
import { debounce }  from "lodash";

export default {
  data() {
    return {
      loading:   false,
      chartTypeOptions: ["table", "pie"],
      chartType: "table",
      pageOptions: [20, 50, 100, 200],
      sortBy: "count",
      sortDesc: true,
      dimensionSelection: {
        reviewSegments:   [],
        propertySegments: []
      },
      dimensionObjs: {
        reviewSegments: {
          rating:             { show: true, currentPage: 1, perPage: 20, loading: { data: true, benchmark: true } },
          subrating:          { show: true, currentPage: 1, perPage: 20, loading: { data: true, benchmark: true } },
          travel_composition: { show: true, currentPage: 1, perPage: 20, loading: { data: true, benchmark: true } },
          reviewer_country:   { show: true, currentPage: 1, perPage: 20, loading: { data: true, benchmark: true } },
          language:           { show: true, currentPage: 1, perPage: 20, loading: { data: true, benchmark: true } },
          source:             { show: true, currentPage: 1, perPage: 20, loading: { data: true, benchmark: true } },
        },
        propertySegments: {
          property_type:      { show: true, currentPage: 1, perPage: 20, loading: { data: true, benchmark: true } },
          categories:         { show: true, currentPage: 1, perPage: 20, loading: { data: true, benchmark: true } },
          country:            { show: true, currentPage: 1, perPage: 20, loading: { data: true, benchmark: true } },
          regions:            { show: true, currentPage: 1, perPage: 20, loading: { data: true, benchmark: true } },
          city:               { show: true, currentPage: 1, perPage: 20, loading: { data: true, benchmark: true } },
        }
      },
      cache: {},
      ratingCols: ["benchmark_rating", "rating", "orig_rating"],
      translateKeys: {
        travel_composition: "travel_composition",
        reviewer_country:   "countries",
        language:           "locales",
        property_type:      "property_types"
      },
      filterKeys: ["categories", "city", "continent", "country", "end_date", "group_ids", "language", "page_context", "property_type", "regions", "reviewer_country", "source", "sources", "start_date", "sub_context", "subscription_ids", "travel_composition", "tripadvisor_rating"]
    };
  },
  props: ["type", "filterParams", "user"],
  components: { Multiselect, OleryTable, TopicSelector },
  computed: {
    countSlug() {
      if (this.type == 'sentiment') return 'mentions_count'
      return 'reviews_count'
    },
    computedDimensionObjs() {
      return Object.values(this.dimensionObjs).reduce((r, c) => {
        Object.keys(c).forEach(d => {
          if (c[d].show) r[d] = c[d];
        });
        return r;
      }, {});
    },
    dimensionNames() {
      return {
        rating:             this.$t('ratings.rating_comparison.rating'),
        subrating:          this.$t('ratings.rating_comparison.subrating'),
        travel_composition: this.$t('ratings.rating_comparison.travel_composition'),
        reviewer_country:   this.$t('ratings.rating_comparison.reviewer_country'),
        language:           this.$t('ratings.rating_comparison.language'),
        source:             this.$t('ratings.rating_comparison.source'),
        property_type:      this.$t('ratings.rating_comparison.vertical'),
        categories:         this.$t('ratings.rating_comparison.category'),
        country:            this.$t('ratings.rating_comparison.country'),
        regions:            this.$t('ratings.rating_comparison.region'),
        city:               this.$t('ratings.rating_comparison.city')
      }
    },
    keys() {
      return {
        ranking:          this.$t('ratings.rating_comparison.ranking'),
        kpi:              this.$t('ratings.rating_comparison.kpi'),
        count:            this.$t('ratings.rating_comparison.'+this.countSlug),
        benchmark_count:  this.$t('ratings.rating_comparison.benchmark_count'),
        ratio:            this.$t('ratings.rating_comparison.ratio'),
        benchmark_ratio:  this.$t('ratings.rating_comparison.benchmark_ratio'),
        rating:           this.$t('ratings.rating_comparison.rating'),
        benchmark_rating: this.$t('ratings.rating_comparison.benchmark_rating')
      }
    },
    computedFilterParams() {
      const params = this.filterParams;
      return {
        data:      { ...params.data,      source: params.data?.sources,      exclude_source: params.data?.exclude_sources      },
        benchmark: { ...params.benchmark, source: params.benchmark?.sources, exclude_source: params.benchmark?.exclude_sources }
      };
    },
    stringParams() {
      return {
        data:      this.stringifyParams(this.computedFilterParams.data),
        benchmark: this.stringifyParams(this.computedFilterParams.benchmark)
      };
    },
    computedData() {
      const data      = this.cache[this.stringParams.data] || {};
      const benchmark = this.cache[this.stringParams.benchmark] || {};

      Object.keys(data).forEach(rt => {
        Object.keys(data[rt]).forEach(d => {
          data[rt][d].forEach(s => {
            let label = s.kpi;
            if (!/source|country|regions|city|categories/.test(d) && label) {
              if (/^(rating|subrating)$/.test(d)) {
                if (label == "Food and Beverages") label = "fnb";
                label = this.$t(`ratings.rating_comparison.kpis.${label}`);
              }
              else label = this.$t(`${this.translateKeys[d]}.${Utils.standardizeScoreName(label, d)}`);
            }
            s.label = label;

            if (!this.benchmarkEnabled) return;
            const b = (benchmark[rt]?.[d] || []).find(b => b.kpi == s.kpi);
            s.benchmark_count  = b?.count;
            s.benchmark_rating = b?.rating;
            s.benchmark_ratio  = b?.ratio;
          });
        });
      });
      return data;
    },
    selection() {
      const selection = [...this.dimensionSelection.reviewSegments, ...this.dimensionSelection.propertySegments];
      return Object.keys(this.computedDimensionObjs).filter(d => selection.includes(d));
    }
  },
  watch: {
    filterParams: function () {
      this.loadListener();
    },
    type: {
      handler: function () {
        if (this.type == "numerical") this.dimensionObjs.reviewSegments.subrating.show = false;
        else this.dimensionObjs.reviewSegments.subrating.show = true;
        this.loadListener();
      },
      immediate: true
    },
    selection: debounce(function () {
      this.checkSegmentSelection();
      this.loadListener();
    }, 1000)
  },
  methods: {
    async loadListener() {
      if (!Object.keys(this.filterParams).length || !this.selection.length || this.loading) return;
      this.loading = true; // just to check if we are already loading
      this.benchmarkEnabled = this.filterParams.benchmarkEnabled;
      await Promise.all(this.selection.map(d => this.loadDimension(d)));
      this.loading = false;
    },
    async loadDimension(dimension) {
      await this.load(this.computedFilterParams.data, "data", dimension);
      if (this.benchmarkEnabled)
        await this.load(this.computedFilterParams.benchmark, "benchmark", dimension);
      if (this.type == "tes") this.generateTES(dimension);
    },
    async load(params, block, dimension) {
      if (block == "benchmark" && !this.benchmarkEnabled) return;
      const stringParams = this.stringParams[block];
      const segment = Object.keys(this.dimensionObjs).find(s => this.dimensionObjs[s][dimension]);

      if ((this.type != "sentiment" && dimension == "subrating") || this.cache[stringParams]?.[this.type]?.[dimension]?.length) return;

      this.dimensionObjs[segment][dimension].loading[block] = true;
      this.cache = { ...this.cache, [stringParams]: { ...(this.cache[stringParams] || {}), [this.type]: { ...(this.cache[stringParams]?.[this.type] || {}), [dimension]: [] } } };

      if (["numerical", "tes"].includes(this.type)) {
        if (dimension == "rating") {
          let res = await axios.get("/v3/destination/ratings", { params: { ...params, group_by: "ratings" } });
          this.setRatingFromRatings(block, res);
        }
        else {
          let res = await axios.get("/v3/destination/stats", { params: { ...params, segment: dimension } });
          this.setRatingFromStats(block, dimension, res);
        }
      }
      if (["sentiment", "tes"].includes(this.type)) {
        if (dimension == "rating") {
          let res = await axios.get("/v3/destination/sentiment", { params: { ...params, group_by: "ratings" } });
          this.setSentiment(block, "rating", res);
        }
        else {
          let res = await axios.get("/v3/destination/sentiment", { params: { ...params, ...(dimension != "subrating" && { segment: dimension }) } });
          this.setSentiment(block, dimension, res);
        }
      }
      this.dimensionObjs[segment][dimension].loading[block] = false;
    },
    stringifyParams(params = {}) {
      const p = this.filterKeys.flatMap(k => [k, `exclude_${k}`]).reduce((res, key) => {
        if (params[key]) res[key] = params[key];
        return res;
      },
      {});
      return JSON.stringify({ ...p });
    },
    columns(dimension, k, isExport) {
      let fields = [
        { [k]: "ranking",          text: this.$t('ratings.rating_comparison.ranking'),          sortable: true, type: "number" },
        { [k]: "label",            text: this.$t('ratings.rating_comparison.kpi'),              sortable: true, type: "text"   },
        { [k]: "count",            text: this.$t('ratings.rating_comparison.'+this.countSlug),  sortable: true, type: "number" },
        { [k]: "benchmark_count",  text: this.$t('ratings.rating_comparison.benchmark_count'),  sortable: true, type: "number" },
        { [k]: "ratio",            text: this.$t('ratings.rating_comparison.ratio'),            sortable: true, type: "text" },
        { [k]: "benchmark_ratio",  text: this.$t('ratings.rating_comparison.benchmark_ratio'),  sortable: true, type: "number" },
        { [k]: isExport ? "orig_rating" : "rating", text: this.$t('ratings.rating_comparison.rating'), sortable: true, type: "number" },
        { [k]: "benchmark_rating", text: this.$t('ratings.rating_comparison.benchmark_rating'), sortable: true, type: "number" },
      ]
      if (!this.benchmarkEnabled) {
        fields.forEach((f,i) => { if (f[k].indexOf('benchmark_') >=0) fields.splice(i, 1) })
      }
      return fields;
    },
    setRatingFromRatings(block, response) {
      var rows   = []
      if (response.data.data[0]) {
        var total = response.data.data[0].ratings.reduce((partial, r) => {
          return partial + r.review_count}
          , 0);
        response.data.data[0].ratings.forEach((rating,i) => {
          rows.push({
            dimension:     'rating',
            ranking:       i + 1,
            kpi:           rating.topic,
            count:         rating.review_count,
            rating:        Number(Utils.round(rating.value / 10)),
            orig_rating:   rating.value / 10,
            ratio:         Utils.round(rating.review_count / total * 100),
          })
        })
      }
      this.cache[this.stringParams[block]].numerical = { ...(this.cache[this.stringParams[block]].numerical || {}), rating: rows };
    },
    setRatingFromStats(block, d, response) {
      var rows  = []
      if (response.data.data[0]) {
        var total = response.data.data.reduce((partial, r) => { return partial + r.stats.review_count }, 0);
        response.data.data.forEach((rating,i) => {
          rows.push({
            dimension:   d,
            ranking:     i + 1,
            kpi:         rating[d],
            count:       rating.stats.review_count,
            rating:      Number(Utils.round(rating.stats.overall_rating_average / 10)),
            orig_rating: rating.stats.overall_rating_average / 10,
            ratio:       Utils.round(rating.stats.review_count / total * 100),
          })
        })
      }
      this.cache[this.stringParams[block]].numerical = { ...(this.cache[this.stringParams[block]].numerical || {}), [d]: rows };
    },
    setSentiment(block, d, response) {
      if (!response.data.data[0]) return

      var rows = [], total
      if (['rating', 'subrating'].includes(d)) {
        total = response.data.data[0].sentiment.reduce((partial, s) => {
          if (d == 'subrating' && s.topic == 'overall') return partial
          return partial + s.opinions_count
        }, 0);
        let i = 1;
        response.data.data[0].sentiment.forEach((s) => {
          if (d == 'subrating' && s.topic == 'overall') return
          rows.push({
            dimension:     d,
            ranking:       i++,
            kpi:           s.topic,
            count:         s.opinions_count,
            reviews_count: s.review_count,
            rating:        Number(Utils.round(s.sentiment_score)),
            orig_rating:   s.sentiment_score,
            ratio:         Utils.round(s.opinions_count / total * 100),
          })
        })
      } else {
        total = response.data.data.reduce((partial, r) => {
          var sentiment = r.sentiment.find(r => r.topic == 'overall')
          return partial + (sentiment ? sentiment.opinions_count || 0 : 0 )
        }, 0);
        response.data.data.forEach((rating,i) => {
          var sentiment = rating.sentiment.find(r => r.topic == 'overall')
          if (!sentiment) return;
          rows.push({
            dimension:     d,
            ranking:       i + 1,
            kpi:           rating[d],
            reviews_count: sentiment.review_count,
            count:         sentiment.opinions_count,
            rating:        Number(Utils.round(sentiment.sentiment_score)),
            orig_rating:   sentiment.sentiment_score,
            ratio:         Utils.round(sentiment.opinions_count / total * 100),
          })
        })
      }
      this.cache[this.stringParams[block]].sentiment = { ...(this.cache[this.stringParams[block]].sentiment || {}), [d]: rows };
    },
    generateTES(dimension) {
      ["data", "benchmark"].forEach(block => {
        if (block == "benchmark" && !this.benchmarkEnabled) return;
        if (dimension == "subrating" && this.type != "sentiment") return;

        this.cache[this.stringParams[block]].tes = { ...(this.cache[this.stringParams[block]].tes || {}), dimension: [] };
        let tes_index = 0;

        if (this.cache[this.stringParams[block]].numerical?.[dimension]) {
          for (let i = 0; i < this.cache[this.stringParams[block]].numerical[dimension].length; i++) {
            let numerical = this.cache[this.stringParams[block]].numerical[dimension][i];
            let r_kpi = numerical.kpi;
            let s = this.cache[this.stringParams[block]].sentiment?.[dimension]?.find(n => n.kpi == r_kpi);
            if (!s) continue;

            this.cache[this.stringParams[block]].tes[dimension][tes_index]        = { dimension: dimension };
            this.cache[this.stringParams[block]].tes[dimension][tes_index].kpi    = r_kpi;

            this.cache[this.stringParams[block]].tes[dimension][tes_index].count  = Math.max(s.reviews_count, numerical.count);
            this.cache[this.stringParams[block]].tes[dimension][tes_index].rating = Utils.calcTES(numerical.rating, numerical.count, s.rating, s.reviews_count);
            this.cache[this.stringParams[block]].tes[dimension][tes_index].ratio  = s.reviews_count > numerical.count ? s.ratio : numerical.ratio;
            tes_index++;
          }
          this.cache[this.stringParams[block]].tes[dimension] = this.cache[this.stringParams[block]].tes[dimension].sort(t => t.ratio);
          this.cache[this.stringParams[block]].tes[dimension].forEach((t, i) => t.ranking = i + 1);
        }
      })
    },
    series(d) {
      let othersLabel = "others";

      if (!this.computedData[this.type][d]) return {};

      let series = { [othersLabel]: { count: 0, label: this.$t("ratings.rating_comparison.kpis.others") } };
      this.computedData[this.type][d].forEach((v, i) => {
        if (i < 9)
          series[v.kpi] = { count: parseInt(v.count), label: v.label };
        else
          series[othersLabel].count += parseInt(v.count);
      })
      if (series[othersLabel]?.count == 0) delete series[othersLabel];
      return series;
    },
    chartOptions(d) {
      return {
        labels: this.translatedSeries(d),
        colors: ["#a3cae0", "#e74c5e", "#47bd9a", "#964b00", "#4090cb", "#f9d570", "#2a8251", "#f408a9", "#26e6ee", "#ffa500", "#0841ab", "#02610d"],
        tooltip: { y: { formatter: val => Utils.formatNumber(val) } },
        legend: {
          show: true,
          position: 'bottom',
          horizontalAlign: 'center',
          verticalAlign: 'middle',
          floating: false,
          fontSize: '14px',
          offsetX: 0,
          offsetY: -10
        },
        responsive: [{
          breakpoint: 600,
          options: {
            chart: { height: 240 },
            legend: { show: false },
          }
        }],
        dataLabels: {
          formatter: val => Utils.formatNumber(Utils.round(val)) + "%"
        }
      }
    },
    translatedSeries(d) {
      return Object.values(this.series(d)).map(s => s.label);
    },
    seriesValues(d) {
      return Object.values(this.series(d)).map(s => s.count).filter(n => n);
    },
    fileName(dimension) {
      return this.$t("general.export_file_name", {
        title: `${this.$t('ratings.rating_comparison.title')}-${this.dimensionNames[dimension]}`,
        sd: this.filterParams.data.start_date,
        ed: this.filterParams.data.end_date
      });
    },
    formatNumber(val) {
      return Utils.formatNumber(val);
    },
    tooltip(d) {
      const vType = this.$t(`ratings.rating_comparison.${this.chartType}`).toLowerCase();
      if (d == "rating") return this.$t("ratings.rating_comparison.rating_tooltip", { vType });
      return this.$t("ratings.rating_comparison.others_tooltip", { vType });
    },
    showSection(dimension) {
      return (dimension != "subrating" || this.type == "sentiment") && (this.selection.includes(dimension));
    },
    checkSegmentSelection() {
      if (!this.dimensionSelection.reviewSegments.length && !this.dimensionSelection.propertySegments.length)
        this.dimensionSelection.reviewSegments = Object.keys(this.dimensionObjs.reviewSegments);
    }
  }
};
</script>

<template>
  <div style='min-height: 200px;' class="card">
    <div class="card-body">
      <div class="navbar-header shadow-none row gx-0 mb-3 p-0">
        <div class="d-flex col-12 col-md-9 flex-column pe-2">
          <TopicSelector v-model="dimensionSelection.reviewSegments"   type="review_segments"   :options="Object.keys(dimensionObjs.reviewSegments).filter(d => dimensionObjs.reviewSegments[d].show)" translatePath="ratings.rating_comparison" />
          <TopicSelector v-model="dimensionSelection.propertySegments" type="property_segments" :options="Object.keys(dimensionObjs.propertySegments)" translatePath="ratings.rating_comparison" />
        </div>
        <div class="showPie d-flex align-items-center col-12 col-md-5 col-xl-3">
          <label class="form-label mb-1 text-nowrap">{{ $t("daily_operations.analytics.chart_type") }}</label>
          <multiselect class="ms-2" :multiple="false" v-model="chartType" :options="chartTypeOptions" :showLabels="false" :custom-label="l => $t(`ratings.rating_comparison.${l}`)" :allow-empty="false" style="min-width: 200px !important;"></multiselect>
        </div>
      </div>

      <div :class="{ row: chartType == 'pie' }">
        <template v-for="dimension in selection" :key="dimension">

          <template v-if="showSection(dimension)">
            <div v-if="!computedDimensionObjs[dimension].loading.data" class="d-flex flex-column">
              <div v-if="chartType == 'table'">
                <OleryTable
                  v-if="computedData[type][dimension] && (dimension != 'subrating' || type == 'sentiment')"
                  :title="dimensionNames[dimension]"
                  titleClass="m-0 font-size-21"
                  :tooltip="tooltip(dimension)"
                  :btnText="$t('general.export_excel')"
                  btnClass="btn btn-secondary text-nowrap"
                  :ratingCols="ratingCols"
                  :exportCols="columns(dimension, 'value', true)"
                  :fileName="fileName(dimension)"
                  :dataTable="{
                    tableHeaderClass:    'thead-light',
                    items:               computedData[type][dimension],
                    headers:             columns(dimension, 'value', false),
                    headerTextDirection: 'center',
                    bodyTextDirection:   'center',
                    sortBy:              sortBy,
                    sortType:            'desc',
                    pagination:          true
                  }"
                >
                  <template #load-indicator>
                    <div v-if="benchmarkEnabled && computedDimensionObjs[dimension].loading.benchmark" class="fw-bold align-self-end d-flex align-items-center">
                      <div class="spinner-border spinner-border-sm text-success me-2"></div>
                      {{ $t("general.loading_benchmark") }}
                    </div>
                  </template>

                  <template #item-ratio="{ item }">
                    {{ formatNumber(Number(item.ratio)) + "%" }}
                  </template>

                  <template #item-benchmark_ratio="{ item }">
                    {{ formatNumber(Number(item.benchmark_ratio)) + "%" }}
                  </template>
                </OleryTable>
              </div>

              <template v-if="chartType == 'pie'">
                <div class="col-sm-6 col-md-4 p-0">
                  <div class="border border-2 rounded-2 m-2 p-3">
                    <div class="d-flex align-items-center">
                      <label>{{ dimensionNames[dimension] }}</label>
                      <span class="ms-2" v-b-tooltip.hover :title="tooltip(dimension)">
                        <i class="mdi mdi-help-circle"></i>
                      </span>
                    </div>

                    <apexchart
                      v-if="seriesValues(dimension).length"
                      class="apex-charts"
                      height="300"
                      type="pie"
                      dir="ltr"
                      :series="seriesValues(dimension)"
                      :options="chartOptions(dimension)"
                    ></apexchart>
                    <span v-else><br>{{ $t("general.no_data") }}<br></span>
                  </div>
                </div>
              </template>
            </div>

            <template v-else>
              <div class="d-flex align-items-center mb-1">
                <h3 class="title m-0 font-size-21">{{ dimensionNames[dimension] }}</h3>
                <span class="ms-2" v-b-tooltip.hover :title="tooltip(dimension)">
                  <i class="mdi mdi-help-circle"></i>
                </span>
              </div>
              <div class="border border-1 rounded-2 mb-2 py-2">
                <div class="loading" style="min-height: 200px;"></div>
              </div>
            </template>
          </template>
        </template>
      </div>
    </div>
  </div>
</template>
