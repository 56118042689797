import {axios} from '@/js/reports'

export const state   = { documentation: {}, documentationPromise: {} };
export const getters = {};

export const mutations = {
  saveDocumentation(state, { data, params }) {
    state.documentation[params] = data;
  },
  saveDocumentationPromise(state, { promise, params }) {
    state.documentationPromise[params] = promise;
  }
};

export const actions = {
  async fetch({ commit }, params) {
    const stringParams = JSON.stringify({ ...params });

    if (state.documentation[stringParams])        return state.documentation[stringParams];
    if (state.documentationPromise[stringParams]) return state.documentationPromise[stringParams];

    let promise = axios.get("https://docs.olery.com/wp-json/wp/v2/docs", { params }).then(response => {
      const data = response.data?.[0] || {};
      commit("saveDocumentation", { data, params: stringParams });
      return data;
    });

    commit("saveDocumentationPromise", { promise, params: stringParams });
    return promise;
  },
};
