<script>
import DocsButton from "@/components/documentation-button";

export default {
  props:  ["title", "subtitle"],
  inject: ["hasDocumentation"],
  components: { DocsButton }
}
</script>

<template>
  <div class="page-title-box d-flex align-items-center justify-content-between flex-wrap" style="row-gap: 10px;">
    <div class="d-flex align-items-center">
      <div class="d-flex align-items-center">
        <h4 v-if="title" class="font-size-20 m-0">{{ title }}&nbsp;</h4>
        <DocsButton v-if="hasDocumentation && hasDocumentation[$route.name]" class="ms-2" />
      </div>
      <slot name="html"></slot>
    </div>

    <slot name="subtitle"></slot>
  </div>
</template>
