<script>
import PageHeader from "@/components/page-header";
import Filters from "@/components/filter";
import Watchlist from "@/components/watchlist";
import DataUtils from "@/js/data-utils";

export default {
  data() {
    return {
      filterCols:   ["daterange", "language", "reviewer_country", "travel_composition", "sources", "group_companies", "companies", "packages"],
      filterParams: {}
    };
  },
  components: {
    PageHeader,
    Filters,
    Watchlist
  },
  methods: { ...DataUtils }
}
</script>

<template>
  <div class="w-100">
    <PageHeader :title="$t('daily_operations.property_watchlist.title')" />

    <Filters @filter:created="saveFilterParams" @filter:update="saveFilterParams" :cols="filterCols" :showCompetitors="true" :preventSubmit="true" />

    <div class="row">
      <div class="col-xl-12">
        <div v-if="!filterParams.groups_companies" class="card bg-light select-group-company">{{ $t("general.select_group_or_company") }}</div>
        <Watchlist v-show="filterParams.groups_companies" :filterParams="filterParams" type="reputation" />
      </div>
    </div>

  </div>
</template>