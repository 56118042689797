import {axios} from '@/js/reports'
import dayjs from 'dayjs'
import Swal from "sweetalert2"

export default {

  data() {
    return {
      company: { id: null, name: null },
      group:   { id: null, name: null },

      propertyType:  null,
      download:      null,
      pageNumber:    1,
      offset:        20,

      release_date:  null,
      base_date:     null,
      day:           null,
      month:         null,
      year:          null,
      previousMonth: false,
      yearEnd:       false,

      colors: ['#2f2a95', '#418bf5', '#5962a0', '#f1a939', '#e86c74', '#f99cdc', '#657084'],

      sentimentRatings: [
        { field: 'overall_score',     label: 'Overall' },
        { field: 'cleanliness_score', label: 'Cleanliness' },
        { field: 'rooms_score',       label: 'Rooms' },
        { field: 'service_score',     label: 'Service' },
        { field: 'fnb_score',         label: 'Food & Beverages' },
        { field: 'value_score',       label: 'Value' },
      ],

      sources:                [],
      sourceColors:           [],
      sourcesPerPropertyType: {
        accommodation: {
          trip_advisor: '#2f2a95',
          booking_com:  '#418bf5',
          expedia:      '#5962a0',
          hotels_com:   '#f1a939',
          google:       '#e86c74',
        },
        restaurant: {
          open_table:   '#418bf5',
          trip_advisor: '#2f2a95', // same as above
          google:       '#e86c74',
          yelp:         '#f1a939',
        },
        attraction: {
          trip_advisor: '#2f2a95',
          google:       '#e86c74',
          yelp:         '#f1a939',
        },
      },
      sourceHash: {
        booking_com:  'Booking.com',
        expedia:      'Expedia.com',
        hotels_com:   'Hotels.com',
        google:       'Google',
        open_table:   'Open Table',
        trip_advisor: 'TripAdvisor',
        yelp:         'Yelp',
        others:       'Others',
      },

    }
  },

  methods: {
    error (error) {
      Swal.fire(this.$t("general.error"), this.$t("general.contact_support") + JSON.stringify(error.response?.data), "error");
    },
    async replabInit() {
      window.reports.init(this)

      this.company.id = this.$route.query.company_id
      if (this.company.id)
        this.company  = (await axios.get(`/v3/companies/${this.company.id}`).catch(this.error))?.data?.data

      this.group.id = this.$route.query.group_id
      if (this.group.id)
        this.group  = (await axios.get(`/v3/groups/${this.group.id}`)).data.data

      if (!this.company?.id && !this.group?.id) return

      if (this.company?.property_type == 'restaurant')
        this.sentimentRatings = this.sentimentRatings.filter((r) => r.field != 'rooms_score')

      this.propertyType = this.company?.property_type || this.group?.property_type

      this.release_date = this.$route.query.release_date || new Date()
      this.release_date = dayjs(this.release_date).format('YYYY-MM-DD')

      this.base_date    = this.previousMonth ?
        dayjs(this.release_date).subtract(1, 'month').format('YYYY-MM-DD') : this.release_date

      this.day   = dayjs(this.base_date).format('DD')
      this.month = dayjs(this.base_date).format('MMMM')
      this.year  = dayjs(this.base_date).format('YYYY')

      this.yearEnd = this.$route.query.year_end == 'true'
      if (this.yearEnd) axios.defaults.params.year_end = true

      let sourcesPerPropertyType = this.sourcesPerPropertyType[this.propertyType]
      this.sources       = Object.keys(sourcesPerPropertyType)
      this.sourceColors  = { ...sourcesPerPropertyType, others: '#657084' }
      this.leadingSource = {
        name:  this.sources[0],
        label: this.sourceHash[this.sources[0]],
      }
    },
  },

}
