<script>
import axios from "axios";
import Utils from "@/js/utils";
import DataUtils from "@/js/data-utils";
import ColumnChart from "./chart";
import Table from "./table";
import Multiselect from "vue-multiselect";

export default {
  data() {
    return {
      loading: false,
      cleanSelection: { data: { own: { group: [], property: [] }, competitors: { group: [], property: [] } }, series: {}, options: [], optionsSelection: [], },
      rows: {
        numerical: { ratings: {}, composition: {}, country: {}, language: {}, source: {}, package: {} },
        sentiment: { ratings: {}, composition: {}, country: {}, language: {}, source: {}, package: {} },
        tes:       { ratings: {}, composition: {}, country: {}, language: {}, source: {}, package: {} }
      },
      ratingHash: {
        numerical: "ratings",
        sentiment: "sentiment",
        tes:       "tes"
      },
      valueHash: {
        numerical: "value",
        sentiment: "sentiment_score",
        tes:       "score"
      },
      showSubratings: false,
      parameters: { own: {}, competitors: {} },
      chartTypeOptions: ["column", "table"],
      chartType: "column",
      properties: {},
      scores: ["sustainable_travel", "health_precautions", "sanitary_safety"],
      orderByOptions: ["rating", "review_count"],
      orderBy: "review_count"
    };
  },
  props: ["ratingType", "filterParams", "companyNames", "colors", "user"],
  components: { ColumnChart, Table, Multiselect },
  created() {
    // initial empty values
    Object.keys(this.rows).forEach(rating => {
      Object.keys(this.rows[rating]).forEach(row => this.rows[rating][row] = Utils.deepClone(this.cleanSelection));
    });
  },
  watch: {
    filterParams: {
      handler: function () {
        if (this.ratingType && this.filterParams.data) this.loadListener(this.filterParams);
      },
      immediate: true
    },
    orderBy: {
      handler: function () {
        this.order();
      },
      immediate: true
    }
  },
  methods: {
    ...DataUtils,
    loadListener(params) {
      this.parameters.own = {
        company_ids: params.data.company_ids || [],
        group_ids:   Utils.flattenGroupIds(params.data.group_ids), // parse because it will be looped in loadData
        ...Utils.slice(params.data, ["start_date", "end_date", "sources", "packages"]),
        ...Utils.slice(params.data, ["sources", "countries", "languages", "compositions", "packages"].map(k => `exclude_${k}`)),
        countries:    params.data.reviewer_country,
        languages:    params.data.language,
        compositions: params.data.travel_composition
      };
      this.parameters.competitors = {
        ...this.parameters.own,
        company_ids:  params.competitors?.map(c => c.id) || [],
        group_ids:    Utils.flattenGroupIds(params.data.group_ids),
        sources:      params.benchmark.sources,
        countries:    params.benchmark.reviewer_country,
        languages:    params.benchmark.language,
        compositions: params.benchmark.travel_composition,
        packages:     params.benchmark.packages,
        ...Utils.slice(params.benchmark, ["sources", "countries", "languages", "compositions", "packages"].map(k => `exclude_${k}`))
      };

      this.clearData();
      this.load();
    },
    async load() {
      this.loading = true;
      let ratingType = this.ratingHash[this.ratingType];

      await this.loadData(ratingType, "company_ids", "group_ids");   // companies and competitors
      await this.loadData(ratingType, "group_ids", "company_ids");   // groups and competitors
      this.fillSeries();
      this.order();

      this.loading = false;
    },
    async loadData(ratingType, level, antiLevel) {
      let levelKey  = level == "company_ids" ? "property" : "group";
      let params = this.parameters.own;
      let promises = [];

      // selected group_ids / company_ids
      Object.keys(this.rows[this.ratingType]).forEach(row => {
        params[level].forEach(id => {
          promises.push(axios.get(`/v3/${ratingType}/stats`, {
            params: {
              ...params, [antiLevel]: null, [level]: id,
              segment: row,
              ...(row == "ratings" && ratingType == "ratings" && { include_subratings: true })
            }}).then(response => {
              let data = response.data.data;
              if (row == "package") data = data.filter(d => d.segment);
              this.rows[this.ratingType][row].data.own[levelKey] = data;
              if (this.ratingType == "sentiment") this.calculateRatings(this.rows[this.ratingType][row].data.own[levelKey]);
              if (!this.properties[`own_${id}`]) this.properties[`own_${id}`] = { id, name: this.companyNames[id], color: this.colors.own, level, type: "own" };
              this.formatRatings(row, "own", levelKey, this.companyNames[id], this.properties[`own_${id}`]);
          }));
        });
      });

      await Promise.all(promises);

      // competitors
      params = this.parameters.competitors;
      promises = [];
      Object.keys(this.rows[this.ratingType]).forEach(row => {
        params[level].forEach(id => {
          promises.push(axios.get(`/v3/${ratingType}/stats`, {
            params: {
              ...params, [antiLevel]: null, [level]: id,
              competitors: levelKey == "group",
              segment: row,
              ...(row == "ratings" && ratingType == "ratings" && { include_subratings: true })
            }}).then(response => {
              let data = response.data.data;
              if (row == "package") data = data.filter(d => d.segment);
              this.rows[this.ratingType][row].data.competitors[levelKey] = data;
              if (this.ratingType == "sentiment") this.calculateRatings(this.rows[this.ratingType][row].data.competitors[levelKey]);
              let name = this.companyNames[id];
              if (levelKey == "group") name = this.$t("daily_operations.analytics.group_competitors", { name: this.companyNames[id] });
              if (!this.properties[`competitor_${id}`]) this.properties[`competitor_${id}`] = { id, name, color: this.colors.own, level, type: "competitor" };
              this.formatRatings(row, "competitors", levelKey, name, this.properties[`competitor_${id}`]);
          }));
        });
      });

      await Promise.all(promises);
    },
    formatRatings(row, belongsTo, levelKey, name, info) {
      let dataField  = this.ratingHash[this.ratingType];
      let valueField = this.valueHash[this.ratingType];
      let rowData    = this.rows[this.ratingType][row];

      if (row != "ratings") {
        rowData.data[belongsTo][levelKey] = rowData.data[belongsTo][levelKey].map(d => (
          { ...d, topic: d.segment, [valueField]: d[dataField].find(r => r.topic == "overall")?.[valueField], label: this.label(row, d.segment, d.segment_title) }
        ));
      } else {
        rowData.data[belongsTo][levelKey] = rowData.data[belongsTo][levelKey][0]?.[dataField]?.map(d => (
          { ...d, label: this.label(row, d.topic), subratings: d.subratings?.map(s => ({ ...s, subLabel: s.title, label: `${d.title} - ${s.title}`, parent: d.topic })) }
        )) || [];
      }

      // save the data to the series field
      let sortedData = Utils.uniqSorted(rowData.data[belongsTo][levelKey].filter(d => d[valueField] && (this.ratingType == 'numerical' || d.topic != "overall")), valueField);
      this.saveToSeries(sortedData, row, belongsTo, name, valueField, dataField, info);
    },
    saveToSeries(data, row, belongsTo, name, valueField, dataField, info) {
      data.forEach(item => {
        let topic = item.topic;
        let label = item.label;
        let value = Utils.round(this.ratingType == "numerical" ? item[valueField] / 10 : item[valueField]);
        let color = this.colors[belongsTo];
        let review_count = item.review_count;
        if (row != "ratings") review_count = item[dataField].find(t => t.topic == "overall")?.review_count;
        review_count = Number(review_count);

        if (this.rows[this.ratingType][row].series[topic]) {
          this.rows[this.ratingType][row].series[topic] = {
            ...this.rows[this.ratingType][row].series[topic],
            values: [...this.rows[this.ratingType][row].series[topic].values, value],
            xaxis:  [...this.rows[this.ratingType][row].series[topic].xaxis,  name ],
            colors: [...this.rows[this.ratingType][row].series[topic].colors, color],
            review_count: [...this.rows[this.ratingType][row].series[topic].review_count, review_count],
            ...(belongsTo == "own") && {
              sum: this.rows[this.ratingType][row].series[topic].sum + Number(value)*review_count,
              count: this.rows[this.ratingType][row].series[topic].count + review_count
            },
            properties: [...this.rows[this.ratingType][row].series[topic].properties, info],
            ...(item.subratings?.length) && { subratings: [...this.rows[this.ratingType][row].series[topic].subratings, ...item.subratings] },
          };
        } else {
          this.rows[this.ratingType][row].series[topic] = {
            values: [value], xaxis: [name], label, topic, colors: [color],
            review_count: [review_count], properties: [info],
            ...(belongsTo == "own") && { sum: Number(value)*review_count, count: review_count, },
            ...(item.subratings?.length) && { subratings: item.subratings },
          };
        }

        if (item.subratings?.length) this.saveToSeries(item.subratings, row, belongsTo, name, valueField, dataField, info);
      });
    },
    order() {
      Object.keys(this.rows[this.ratingType]).forEach(row => {
        let series = this.rows[this.ratingType][row].series;
        series = Object.entries(series)
          .sort(([, vA], [, vB]) => {
            if (this.orderBy == "rating") return vB.avg - vA.avg;
            return vB.count - vA.count;
          })
          .reduce((res, [k, v]) => ({ ...res, [k]: v }), {});
        this.rows[this.ratingType][row].series = series;

        // get the ordered options
        this.rows[this.ratingType][row].options = Object.values(series).filter(s => {
          return (this.ratingType == 'numerical' || s.topic != "overall") && (row == "ratings" ? s.subratings : true);
        }).map(i => ({ topic: i.topic, label: i.label, ...(row == "ratings" && { subratings: [...new Map(i.subratings.map(b => [b.topic, b])).values()] }) }))

        // get the pre-selection
        this.rows[this.ratingType][row].optionsSelection.length = [];
        Object.values(series).filter(s => {
          if (row == "ratings" && this.ratingType != "tes") return s.subratings || this.scores.includes(s.topic);
          return true;
        }).slice(0, 3).forEach(i => this.rows[this.ratingType][row].optionsSelection.push({ topic: i.topic, label: i.label }));
      });
    },
    label(row, topic, title) {
      if (row == "ratings")     return this.$t(`ratings.rating_comparison.kpis.${topic}`);
      if (row == "composition") return this.$t(`travel_composition.${topic}`);
      if (row == "country")     return this.$t(`countries.${topic}`);
      if (row == "language")    return this.locales(topic).name;
      return title;
    },
    calculateRatings(data) {
      data.forEach(item => {
        // merge sub ratings
        let opinions = this.mergeSubRatings(item.opinions);

        // calculate sentiment for each rating
        item.sentiment = [];
        opinions.forEach(opinion => {
          let calculatedSentiment;
          if (Array.isArray(opinion) && opinion.length) {
            calculatedSentiment = this.calcSentiment(opinion);
            item.sentiment.push({ ...calculatedSentiment, subratings: opinion });
          } else item.sentiment.push({ ...opinion, subratings: [] });
        });
      });
    },
    mergeSubRatings(opinions = []) {
      let result = [];
      opinions.forEach((opinion, index) => {
        if (opinion.rating?.key && (opinion.topic != opinion.rating.key) && !opinion.rating?.counted) {
          let mergeSub = [];
          opinions.slice(index).forEach((op, i) => {
            if ((opinion.rating.key == op.rating?.key) && !op.rating?.counted) {
              mergeSub.push(op);
              opinions[i + index].rating = { ...opinions[i + index].rating, counted: true };
            }
          })
          result.push(mergeSub);
        }
        else if (!opinion.rating?.counted) result.push(opinion);
      });
      return result;
    },
    calcSentiment(opinion = []) {
      let positiveSum = 0, negativeSum = 0, reviewCount = 0, opinionsCount = 0;
      opinion.forEach(op => {
        positiveSum   += (op?.positive_opinions || 0);
        negativeSum   += (op?.negative_opinions || 0);
        reviewCount   += (op?.review_count      || 0);
        opinionsCount += (op?.opinions_count    || 0);
      });

      let topic = opinion[0].rating.key;
      let label = this.$t(`ratings.rating_comparison.kpis.${topic}`);

      let sentimentScore = 5;
      let nominator      = positiveSum - negativeSum;
      let denominator    = nominator > 0 ? positiveSum : negativeSum;
      sentimentScore     = 5 + (5 * nominator / denominator);

      return {
        topic: topic,
        title: label,
        label: label,
        rating: { key: topic, label: label },
        review_count:        reviewCount,
        opinions_count:      opinionsCount,
        positive_opinions:   positiveSum,
        negative_opinions:   negativeSum,
        neutral_opinions:    opinionsCount - positiveSum - negativeSum,
        sentiment_score:     sentimentScore,
        positive_percentage: positiveSum / (positiveSum + negativeSum)
      };
    },
    clearData() {
      this.showSubratings = false;
      this.properties = {};
      Object.keys(this.rows[this.ratingType]).forEach(row => this.rows[this.ratingType][row] = Utils.deepClone(this.cleanSelection));
    },
    toggleSubratings(showSub) {
      if (showSub) {
        let selection = this.rows[this.ratingType]["ratings"].optionsSelection.map(s => s.topic);
        this.rows[this.ratingType]["ratings"].optionsSelection = this.rows[this.ratingType]["ratings"].options
          .filter(o => selection.includes(o.topic))
          .flatMap(o => o.subratings);
      } else {
        let selection = this.rows[this.ratingType]["ratings"].optionsSelection.map(s => s.parent);
        this.rows[this.ratingType]["ratings"].optionsSelection = this.rows[this.ratingType]["ratings"].options
          .filter(o => selection.includes(o.topic));
      }
    },
    fillSeries() {
      const properties = Object.values(this.properties);
      Object.keys(this.rows[this.ratingType]).forEach(row => {
        Object.values(this.rows[this.ratingType][row].series).forEach(topic => {
          topic.avg = topic.sum / topic.count;
          properties.forEach(p => {
            if (!topic.xaxis?.includes(p.name)) {
              topic.colors?.push(p.color);
              topic.values?.push(null);
              topic.xaxis?.push(p.name);
              topic.review_count?.push(null);
              topic.properties?.push(p);
            }
          });
        });
      });
    }
  },
  computed: {
    seriesToShow() {
      if (this.loading) return [];
      return Object.keys(this.rows[this.ratingType]).reduce((res, row) => {
        let seriesData = this.rows[this.ratingType][row].series;
        let ratings = this.rows[this.ratingType][row].optionsSelection.map(o => o.topic);
        if (ratings.length) res[row] = Object.keys(seriesData).filter(t => ratings.includes(t)).map(t => seriesData[t]);
        return res;
      }, {});
    }
  }
}
</script>

<template>
  <div class="mt-1 card" :class="{ 'loading': loading }">
    <div class="card-body" v-if="ratingType">
      <div class="d-flex align-items-center justify-content-between row mb-2">
        <div class="col-sm-12 col-lg-auto col-xxl-6">
          <h4 class="mb-1 float-start">{{ $t("daily_operations.analytics.segmented") }}</h4>
        </div>

        <div class="col-sm-12 mt-3 mt-xl-0 col-xl-auto col-xxl-6 row">
          <!-- order by -->
          <div class="col-12 col-md-6">
            <label class="form-label mb-1">{{ $t("daily_operations.analytics.order_by") }}</label>
            <multiselect :multiple="false" v-model="orderBy" :options="orderByOptions" :showLabels="false" :custom-label="l => $t(`daily_operations.analytics.${l}`)" :allow-empty="false" style="min-width: 200px !important;"></multiselect>
          </div>

          <!-- chart type -->
          <div class="col-12 col-md-6">
            <label class="form-label mb-1">{{ $t("daily_operations.analytics.chart_type") }}</label>
            <multiselect :multiple="false" v-model="chartType" :options="chartTypeOptions" :showLabels="false" :custom-label="l => $t(`daily_operations.analytics.${l}`)" :allow-empty="false" style="min-width: 200px !important;"></multiselect>
          </div>
        </div>
      </div>

      <!-- chart sections -->
      <template v-for="(row, i) in Object.keys(rows[ratingType])" :key="row">
        <div :class="{ 'mt-5': i > 0, 'mt-2': i == 0 }" v-if="row != 'package' || user.admin">
          <div class="d-flex align-items-center mb-3 justify-content-between row">
            <h5 class="mb-3 mb-sm-1 col-12 col-sm-5">{{ $t(`daily_operations.analytics.segments.${row}`) }}</h5>

            <div class="d-sm-flex align-items-center col-12 col-sm-7 row flex-row-reverse">
              <div class="ms-0 ms-sm-4 col-sm-auto" v-if="(!showSubratings) || row != 'ratings' || ratingType == 'tes'">
                <multiselect :multiple="true" v-model="rows[ratingType][row].optionsSelection" :options="rows[ratingType][row].options" label="label" track-by="topic" :showLabels="false" :placeholder="$t(`daily_operations.analytics.select.${row}`)" style="min-width: 200px !important;"></multiselect>
              </div>

              <div class="ms-0 ms-sm-4 col-sm-auto" v-else-if="showSubratings && row == 'ratings' && ratingType != 'tes'">
                <multiselect :multiple="true" v-model="rows[ratingType][row].optionsSelection" :options="rows[ratingType][row].options" group-values="subratings" group-label="label" :group-select="true" label="label" track-by="topic" :showLabels="false" :placeholder="$t(`daily_operations.analytics.select.${row}`)" style="min-width: 200px !important;"></multiselect>
              </div>

              <div class="mb-1 d-flex align-items-center col-sm-auto" v-if="row == 'ratings' && ratingType != 'tes'">
                <b-form-checkbox id="showSubratings" v-model="showSubratings" switch class="mb-3 pt-2" size="lg" @change="toggleSubratings">
                  <label for="showSubratings" class="font-size-14">{{ $t("daily_operations.analytics.show_sub_ratings") }}</label>
                </b-form-checkbox>
              </div>
            </div>
          </div>

          <div v-if="seriesToShow[row] && seriesToShow[row].length" class="row">
            <template v-for="(series, index) in seriesToShow[row]" :key="`${row}_${index}`">
              <ColumnChart v-if="!loading && chartType == 'column'" :data="series" :segment="row" />
              <Table v-if="!loading && chartType == 'table'" :data="series" :segment="row" />
            </template>
          </div>

          <div v-else class="d-flex align-items-center justify-content-center border border-2 rounded fw-bold" style="min-height: 70px;">
            {{ $t("daily_operations.analytics.segments.no_data", { s: $tc(`daily_operations.analytics.segments.${row}`, 2).toLowerCase() }) }}
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
