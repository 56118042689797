export default {
  "sd":          "data.start_date",
  "ed":          "data.end_date",
  "sid":         "data.subscription_ids",
  "pt":          "data.property_type",
  "cnt":         "data.continent",
  "cn":          "data.country",
  "rg":          "data.regions",
  "ct":          "data.city",
  "cat":         "data.categories",
  "srcs":        "data.sources",
  "src":         "data.source",
  "rt":          "data.rating_type",
  "gid":         "data.group_ids",
  "f":           "data.finished",
  "pub":         "data.publish",
  "rcn":         "data.reviewer_country",
  "l":           "data.language",
  "tar":         "data.tripadvisor_rating",
  "tc":          "data.travel_composition",
  "custom.n":    "data.customerFilter.n",
  "cids":        "data.company_ids",
  "s":           "data.search",
  "pg":          "data.page",
  "rl":          "data.response_language",
  "qt":          "data.question_type",
  "sent":        "data.has_sentiment",
  "svid":        "data.survey_id",
  "tp":          "data.time_period",
  "ut":          "data.user_type",
  "t":           "data.topics",
  "wr":          "data.with_responses",
  "pckg":        "data.packages",
  "b.pt":        "benchmark.property_type",
  "b.cnt":       "benchmark.continent",
  "b.cn":        "benchmark.country",
  "b.rg":        "benchmark.regions",
  "b.ct":        "benchmark.city",
  "b.cat":       "benchmark.categories",
  "b.rcn":       "benchmark.reviewer_country",
  "b.l":         "benchmark.language",
  "b.tar":       "benchmark.tripadvisor_rating",
  "b.tc":        "benchmark.travel_composition",
  "b.sd":        "benchmark.start_date",
  "b.ed":        "benchmark.end_date",
  "b.gid":       "benchmark.group_ids",
  "b.sid":       "benchmark.subscription_ids",
  "be":          "benchmarkEnabled",
  "bt":          "benchmark_type",
  "b.srcs":      "benchmark.sources",
  "b.src":       "benchmark.source",
  "b.rr":        "benchmark.rating_range",
  "b.pckg":      "benchmark.packages",
  "tab":         "tab",
  "p":           "period",
  "ps":          "periods",
  "ss":          "survey_status",
  "cid":         "data.company_id",
  "rpt":         "report_templates",
  "c":           "competitors",
  "rid":         "review_id",
  "rr":          "data.rating_range",
  "y":           "year",
  "m":           "month",
  "dt":          "data.distribution_types",
  "st":          "data.source_type",
  "srcs.e":      "data.exclude.sources",
  "rcn.e":       "data.exclude.reviewer_country",
  "l.e":         "data.exclude.language",
  "tc.e":        "data.exclude.travel_composition",
  "gid.e":       "data.exclude.group_ids",
  "cids.e":      "data.exclude.company_ids",
  "t.e":         "data.exclude.topics",
  "cat.e":       "data.exclude.categories",
  "cnt.e":       "data.exclude.continent",
  "cn.e":        "data.exclude.country",
  "rg.e":        "data.exclude.regions",
  "ct.e":        "data.exclude.city",
  "pckg.e":      "data.exclude.packages",
  "b.srcs.e":    "benchmark.exclude.sources",
  "b.rcn.e":     "benchmark.exclude.reviewer_country",
  "b.l.e":       "benchmark.exclude.language",
  "b.tc.e":      "benchmark.exclude.travel_composition",
  "b.t.e":       "benchmark.exclude.topics",
  "b.cat.e":     "benchmark.exclude.categories",
  "b.cnt.e":     "benchmark.exclude.continent",
  "b.cn.e":      "benchmark.exclude.country",
  "b.rg.e":      "benchmark.exclude.regions",
  "b.ct.e":      "benchmark.exclude.city",
  "b.pckg.e":    "benchmark.exclude.packages",
  "code":        "code",
  "ep":          "ep",
  "token":       "token",
  "auth_token":  "auth_token",
  "override_user": "override_user",
  "uid":           "user_id",
  "v":             "dataViewType",
  "dp":            "dataFormatType",
  "at":            "areaType",
  "env":           "environments",
  "pos":           "pos"
};