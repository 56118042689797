<script>
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";

export default {
  page: {
    title: "Destination Dashboard",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { PageHeader },
  props: ["routes"],
  data() {
    return {
      user: null
    };
  },
  beforeMount () {
    this.$store.dispatch("user/fetch").then((data) => {
      this.user = data;
    });
  },
  computed: {
    contracts() {
      return this.$store.getters["contract/contracts"];
    }
  },
  methods: {
    findChild(routes = [], name = "") {
      for (let i = 0; i < routes.length; i++) {
        if (routes[i].name == name) return routes[i];
        if (routes[i].children?.length) {
          const result = this.findChild(routes[i].children, name);
          if (result) return result;
        }
      }
      return null;
    },
    findFirstChild(route = {}) {
      if (!route.children?.length) return route;
      return this.findFirstChild(route.children[0]);
    },
    redirectPath(route) {
      const redirect = route.meta.redirect;
      if (redirect == "/") return "root";
      let link = this.findChild(route.children, redirect);
      if (!link) link = this.findFirstChild(route);
      return link.name;
    }
  }
};
</script>

<template>
  <div class="w-100">
    <PageHeader :title="$t('selector.title')" />

    <template v-if="user && contracts.length">
      <template v-for="p in (routes || [])" :key="p.meta.name">
        <div v-if="p.meta.name != 'settings'" class="card" >
          <router-link :to="{ name: redirectPath(p) }">
            <div class="card-body">
              {{ $t(`selector.${p.meta.name}`) }}
            </div>
          </router-link>
        </div>
      </template>
    </template>

    <div v-else-if="user" class="card bg-light select-group-company flex-row">
      <i class="mdi mdi-lock-alert-outline font-size-22 me-2"></i>
      {{ $t("general.user_without_contracts") }}
    </div>
  </div>
</template>
