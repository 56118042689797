<script>
import PageHeader  from "@/components/page-header";
import DataUtils   from "@/js/data-utils";
import Multiselect from "vue-multiselect";
import OleryTable  from "@/components/olery-table";
import dayjs       from "dayjs";
import axios       from "axios";
import Utils       from "@/js/utils";
import Swal        from "sweetalert2";
import Filters     from "@/components/filter";

export default {
  data() {
    return {
      loading: true,
      colsToShow: ["lemma", "aspects", "poses", "environment", "created_at", "actions"],
      filterCols: ["search", "pos", "environment"],
      sortBy: "lemma",
      sortDesc: true,
      perPage: 50,
      currentPage: 1,
      modal: false,
      modalType: "",
      newTopic: "",
      user: {},
      contractIds: [],
      languages: [],
      currentLanguage: { id: 43, code: "en" },
      lexicons: [],
      topics: [],
      editLexicon: { lemma: "", aspects: [], poses: [] },
      posOptions: ["adj", "adv", "noun", "prep", "verb", "multi_word_expression", "other"],
      filterParams: {}
    };
  },
  components: { PageHeader, Multiselect, OleryTable, Filters },
  async created() {
    await this.loadUser();
    await this.loadLanguages();
    await this.loadTopics();
  },
  watch: {
    contract: {
      handler: function () {
        if (this.contract?.id) this.loadLexicons();
      },
      immediate: true
    },
    currentLanguage: function () {
      if (this.currentLanguage?.id) this.loadLexicons();
    }
  },
  methods: {
    ...DataUtils,
    ...Utils.date,
    async loadUser() {
      this.user = await this.$store.dispatch("user/fetch");
      ["destination", "documentation", "feedback", "report", "reputation"].forEach(k => {
        this.user[`${k}_contracts`].forEach(c => this.contractIds.push(c.id));
      });
      this.contractIds = [...new Set(this.contractIds)];
    },
    async loadLanguages() {
      this.loading = true;
      this.languages = await this.$store.dispatch("lexicons/fetchLanguages");
      this.loading = false;
    },
    async loadTopics() {
      const topics = await this.$store.dispatch("topics/fetch");
      this.topics = topics.filter(t => {
        return this.contractIds.filter(c => (t.contract_ids || []).includes(c)).length;
      });
    },
    async loadLexicons() {
      this.loading = true;
      this.lexicons = await this.$store.dispatch("lexicons/fetchLexicons", { language_id: this.currentLanguage.id, contract_ids: this.contract.id });
      this.loading = false;
    },
    async saveLexicon() {
      this.loading = true;
      try {
        const value = { ...this.editLexicon, aspects: this.editLexicon.aspects.map(s => s.topic) };
        await axios.post("/v3/lexicons", { ...value, contract_id: this.contract.id });
        await this.$store.commit("lexicons/saveLexicons", {
          data: [{ ...value }],
          params: JSON.stringify({ language_id: this.currentLanguage.id, contract_ids: this.contract.id })
        });
        this.lexicons = [...[{ ...this.editLexicon }], ...this.lexicons];
        this.toggleModal(false);
      } catch (err) { this.error(err); }
      this.loading = false;
    },
    async deleteLexicon(item) {
      this.loading = true;
      try {
        await axios.delete(`/v3/lexicons/${item.id}`);
      } catch (err) { this.error(err); }
      this.loading = false;
    },
    async saveTopic() {
      this.loading = true;
      try {
        const topic = Utils.slugify(this.newTopic);
        const response = await axios.post("/v3/sentiment/topics", { topic, label: this.newTopic, subscription_id: this.contract.id });
        const data = response.data?.data || {};
        // let data = { topic, label: this.newTopic, contract_ids: [this.contract.id] }
        await this.$store.commit("topics/saveTopics", { topics: [data], contractId: this.contract.id });
        this.topics = [{ ...data }, ...this.topics];
        this.toggleModal(false);
      } catch (err) { this.error(err); }
      this.loading = false;
    },
    save() {
      if (this.modalType == "lexicon") this.saveLexicon();
      else this.saveTopic();
    },
    columns(k) {
      let fields = [
        { [k]: "lemma",            text: this.$t("sentiment_playground.lemma"),            type: "text",              sortable: true  },
        { [k]: "from_lemma",       text: this.$t("sentiment_playground.from_lemma"),       type: "text",              sortable: true  },
        { [k]: "aspects",          text: this.$t("sentiment_playground.topics"),           type: "text",              sortable: true  },
        { [k]: "poses",            text: this.$t("sentiment_playground.poses"),            type: "select_with_label", sortable: true  },
        { [k]: "type",             text: this.$t("sentiment_playground.type"),             type: "select",            sortable: true  },
        { [k]: "polarity",         text: this.$t("sentiment_playground.polarity"),         type: "select",            sortable: true  },
        { [k]: "environment",      text: this.$t("sentiment_playground.environment"),      type: "select",            sortable: true  },
        { [k]: "confidence_level", text: this.$t("sentiment_playground.confidence_level"), type: "none",              sortable: true  },
        { [k]: "strength",         text: this.$t("sentiment_playground.strength"),         type: "none",              sortable: true  },
        { [k]: "component",        text: this.$t("sentiment_playground.component"),        type: "select",            sortable: true  },
        { [k]: "reviewed",         text: this.$t("sentiment_playground.reviewed"),         type: "select",            sortable: true  },
        { [k]: "property_types",   text: this.$t("sentiment_playground.property_types"),   type: "select_multiple",   sortable: true  },
        { [k]: "created_at",       text: this.$t("sentiment_playground.created_at"),       type: "none",              sortable: true  },
        { [k]: "actions",          text: this.$t("sentiment_playground.actions"),          type: "none",              sortable: false }
      ];

      return fields.filter(f => this.colsToShow.includes(f[k]));
    },
    toggleModal(open, type, item = { lemma: "", aspects: [], poses: [] }) {
      this.modalType   = type;
      this.editLexicon = item;
      this.modal       = open;
    },
    joinItems(items = [], translationPath = "") {
      return items.map(i => {
        if (translationPath == "topics") return this.translateTopic(i);
        return this.$t(`${translationPath}.${i}`);
      }).join(", ");
    },
    formatDate(template) {
      if (!template.created_at) return "-";
      return dayjs(template.created_at).format("DD MMM YYYY - HH:mm");
    },
    translateTopic(topic) {
      const path = `topics.${topic.topic}`;
      return this.$te(path) ? this.$t(path) : topic.label;
    },
    error(error) {
      Swal.fire(this.$t("general.error"), this.$t("general.contact_support") + JSON.stringify(error?.response?.data || error), "error");
      console.error("Error: ", error)
      this.loading = false;
    }
  },
  computed: {
    contract() {
      return this.$store.getters["contract/currentContract"];
    },
    filteredLexicons() {
      let p = this.filterParams;
      if (!p.data || !p.data.search) return this.lexicons;

      let lexicons = [...this.lexicons];
      if (p.data.search?.length) {
        let str = p.data.search.toUpperCase();
        lexicons = lexicons.filter(s => {
          return (s.lemma || "").toUpperCase().includes(str);
        });
      }

      return lexicons;
    },
    modalTitle() {
      if (this.modalType == "lexicon") {
        if (this.editLexicon.id) return this.$t("sentiment_playground.edit_lexicon");
        return this.$t("sentiment_playground.new_lexicon");
      }
      return this.$t("sentiment_playground.new_topic");
    },
    validFields() {
      if (this.modalType == "lexicon") return this.editLexicon.lemma?.length;
      return this.newTopic.length;
    }
  }
}
</script>

<template>
  <div class="w-100">
    <PageHeader :title="$t('sentiment_playground.list')" />

    <Filters @filter:created="saveFilterParams" @filter:change="saveFilterParams" :cols="filterCols" :notifyChange="true" />

    <div class="row">
      <div class="col-xl-12">
        <div style="min-height: 200px;" class="card" :class="{ loading }">
          <div class="card-body">
            <div class="d-flex align-items-center justify-content-between mb-3">
              <multiselect class="w-25" v-model="currentLanguage" :multiple="false" :options="languages" :showLabels="false" :allow-empty="false" :placeholder="$t('new_survey.questions.edit.language')">
                <template v-slot:singleLabel="props">
                  <div class="d-flex align-items-center">
                    <span :class="`flag flag-${locales(props.option.code, 'language').code}`" class="me-2"></span>
                    <span> {{ locales(props.option.code).name }} </span>
                  </div>
                </template>

                <template v-slot:option="props">
                  <div class="d-flex align-items-center">
                    <span :class="`flag flag-${locales(props.option.code, 'language').code}`" class="me-1"></span>
                    <span> {{ locales(props.option.code).name }} </span>
                  </div>
                </template>
              </multiselect>

              <div>
                <button class="btn btn-primary me-2" @click="toggleModal(true, 'lexicon')">{{ $t("sentiment_playground.new_lexicon") }}</button>
                <button class="btn btn-primary" @click="toggleModal(true, 'topic')">{{ $t("sentiment_playground.new_topic") }}</button>
              </div>
            </div>

            <OleryTable
              v-if="!loading"
              fileName="lexicons"
              :hideExport="true"
              :dataTable="{
                tableHeaderClass:    'thead-light',
                items:               filteredLexicons,
                headers:             columns('value'),
                headerTextDirection: 'center',
                bodyTextDirection:   'center',
                sortBy:              sortBy,
                sortType:            'desc',
                pagination:          true
              }"
            >
              <template #item-aspects="{ item }">
                {{ joinItems(item.aspects, "topics") }}
              </template>

              <template #item-poses="{ item }">
                {{ joinItems(item.poses, "sentiment_playground.pos_labels") }}
              </template>

              <template #item-created_at="{ item }">
                {{ formatDate(item) }}
              </template>

              <template #item-actions="{ item }">
                <a href="#" @click="toggleModal(true, 'lexicon', item)" class="text-nowrap me-3">
                  <i class="mdi mdi-square-edit-outline"></i>
                  {{ $t("sentiment_playground.edit") }}
                </a>

                <a href="#" @click="deleteLexicon(item)" class="text-nowrap">
                  <i class="mdi mdi-trash-can"></i>
                  {{ $t("sentiment_playground.delete") }}
                </a>
              </template>
            </OleryTable>
          </div>
        </div>
      </div>
    </div>

    <b-modal v-model="modal" @hide="toggleModal(false)" centered hide-footer hide-header size="lg">
      <div class="p-4 d-flex flex-column">
        <h3>{{ modalTitle }}</h3>

        <template v-if="modalType == 'lexicon'">
          <div class="form-group">
            <label class="form-label">{{ $t("sentiment_playground.lexicon") }}</label>
            <input type="text" class="form-control" :class="{ valid: editLexicon.lemma.length, invalid: !editLexicon.lemma.length }" :placeholder="$t('sentiment_playground.lexicon')" v-model="editLexicon.lemma" :state="false" />
          </div>

          <div class="form-group">
            <label class="form-label">{{ $t("sentiment_playground.topics") }}</label><br>
            <multiselect :multiple="true" v-model="editLexicon.aspects" :options="topics" allowEmpty :custom-label="translateTopic" :showLabels="false" :placeholder="$t('sentiment_playground.topics')" ></multiselect>
          </div>

          <div class="form-group">
            <label class="form-label">{{ $t("sentiment_playground.poses") }}</label><br>
            <multiselect :multiple="true" v-model="editLexicon.poses" :options="posOptions" allowEmpty :custom-label="p => $t(`sentiment_playground.pos_labels.${p}`)" :showLabels="false" :placeholder="$t('sentiment_playground.poses')" ></multiselect>
          </div>
        </template>

        <template v-else>
          <div class="form-group">
            <label class="form-label">{{ $t("sentiment_playground.topic") }}</label>
            <input type="text" class="form-control" :class="{ valid: newTopic.length, invalid: !newTopic.length }" :placeholder="$t('sentiment_playground.topic')" v-model="newTopic" :state="false" />
          </div>
        </template>

        <div class="footer-nav d-flex flex-column flex-sm-row justify-content-between mt-4" style="gap:10px;">
          <button class="btn btn-outline-secondary me-2" @click="toggleModal(false)">{{ $t("surveys_list.cancel") }}</button>
          <button @click="save" class="btn btn-primary me-2" :disabled="!validFields" >{{ $t("reports.save") }}</button>
        </div>
      </div>
    </b-modal>
  </div>
</template>
