<script>
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import Multiselect from "vue-multiselect";

import OverallChart from "@/components/overall-chart";
import Kpis from "@/components/kpis";
import RatingComparisson from "./rating-comparisson";
import Filters from "@/components/filter";
import DataUtils from "@/js/data-utils";

export default{
  page: {
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    PageHeader,
    Multiselect,
    OverallChart,
    Kpis,
    RatingComparisson,
    Filters,
  },
  data() {
    return {
      cols: ['daterange', 'property_type', 'continent', 'country', 'regions', 'city', 'category', 'group', 'reviewer_country', 'language', 'tripadvisor_rating', 'sources', 'travel_composition', 'filters' ],
      ratingTypes: [ 'numerical', 'sentiment', 'tes' ],
      chartHash: { numerical: ['reviews_count', 'numerical_rating'], sentiment: ['mentions_count', 'sentiment_rating'], tes: ['mentions_count', 'tes_score'] },
      kpiHash:   { numerical: 'rating' },
      type: this.$route.query['rt'] || this.$route.query['data.rating_type'] || 'numerical',
      filterParams: {},
      user: {}
    };
  },
  computed: {
    title () {
      return this.ratingNames(this.type)
    },
    kpiCols () {
      return [this.kpiHash[this.type] || this.type,  'review_count']
    },
    countSlug () {
      if (this.type == 'sentiment') return 'number_mentions'
      return 'number_reviews'
    }
  },
  methods: {
    ...DataUtils,
    ratingNames (r) {
      return { numerical: this.$t('ratings.numerical'), sentiment: this.$t('ratings.sentiment'), tes: this.$t('ratings.tes')}[r]
    },
    onRatingTypeChange (value) {
      this.$router.push({ name: this.$route.name, query: { ...this.$route.query, 'rt': value } }).catch(() => {})
    },
  },
  async created() {
    this.user = await this.$store.dispatch("user/fetch");
  },
  mounted() {
    if (this.$route.query['rt'] || this.$route.query['data.rating_type']) return;
    this.$router.push({ name: this.$route.name, query: { ...this.$route.query, 'rt': 'numerical' } }).catch(() => {})
  }
};
</script>

<template>
  <div class="w-100">
    <div class="row mt-3">
      <div class="col-lg-12">
        <div class="card mb-0">
          <div class="card-body">
            <h4 class="card-title d-print-none">{{ $t('ratings.select_rating_type') }}</h4>
            <div class="row mb-1">
              <label class='col-sm-2 mt-2 form-label'>{{ $t('ratings.rating_type') }}</label>
              <div class="col-sm-4">
                <multiselect v-model="type" :options="ratingTypes" :custom-label='ratingNames' :allow-empty="false" @select="onRatingTypeChange" @remove="onRatingTypeChange"></multiselect>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <PageHeader :title="title" />

    <Filters @filter:created="saveFilterParams" @filter:update="saveFilterParams" :cols='cols' showBenchmark='true'/>

    <div class="row">
      <div class="col-lg-12">
        <OverallChart :filterParams="filterParams" :type='type' :cols="chartHash[type]" page='destination_ratings'/>
      </div>
    </div>

    <Kpis :filterParams="filterParams" :cols='kpiCols' :countslug='countSlug' dashboard_type="destination"/>

    <div class="row">
      <div class="col-xl-12">
        <RatingComparisson :filterParams="filterParams" :type='type' :user="user" />
      </div>
    </div>
  </div>
</template>
