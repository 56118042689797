<script>
export default {
  data() {
    return {
      model: false
    };
  },
  props: ["modelValue"],
  methods: {
    updateValue(ev) {
      ev.preventDefault();
      ev.stopPropagation();
      this.$emit("update:modelValue", this.model);
      this.$emit("change", { type: "exclusion", value: this.model });
    },
  },
  watch: {
    modelValue: {
      handler: function () {
        this.model = this.modelValue;
      },
      immediate: true,
      deep: true
    }
  }
};
</script>

<template>
  <div class="exclusion-container" v-b-tooltip.hover :title="$t('filter.exclude_tooltip')">
    <input class="exclusion-checkbox" type="checkbox" v-model="model" @change="updateValue">
  </div>
</template>