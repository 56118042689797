<script>
import ExportXLSX from "@/components/export-xlsx";
import Utils      from "@/js/utils";

export default {
  data() {
    return {
      defaultPageOptions: [20, 50, 100, 200],
      rowsPerPage:        20,
      copyRowsPerPage:    20,
      currentPage:        1,
      exporting:          false
    }
  },

  props: ["title", "tooltip", "titleClass", "btnText", "btnClass", "ratingCols", "exportCols", "dataTable", "fileName", "pageOptions", "hideExport", "loading"],
  components: { ExportXLSX },
  created() {
    this.rowsPerPage = this.dataTable?.pagination ? this.rowOptions[0] : this.dataTable?.items.length;
    this.copyRowsPerPage = Utils.deepClone(this.rowsPerPage);
    this.updateRowsPerPage();
  },
  methods: {
    tdClass(value, scale) {
      const val = value ? value * (scale || 1) : value;
      return Utils.ratings.toCss(val);
    },
    cellClass(col) {
      if (this.ratingCols?.includes(col)) return "h-inherit p-0";
      return "";
    },
    paginate(page) {
      this.$refs[this.dataTableRef]?.updatePage(page);
    },
    updateRowsPerPage(trigger) {
      if (trigger == "selector") this.copyRowsPerPage = Utils.deepClone(this.rowsPerPage);
      if (this.dataTable) return this.$refs[this.dataTableRef]?.updateRowsPerPageActiveOption(this.rowsPerPage);
    },
    async toggleRowsPerPage(ev) {
      if (ev == "init") {
        this.exporting = true;
        this.rowsPerPage = this.dataTable.items.length;
        await this.updateRowsPerPage();
        this.paginate(1);
      } else {
        this.exporting = false;
        this.rowsPerPage = Utils.deepClone(this.copyRowsPerPage);
        await this.updateRowsPerPage();
        this.paginate(this.currentPage);
      }
    },
    formatter(item, col) {
      const colData = this.headers.find(h => h.value == col);
      if (colData.type == "number") return Utils.formatNumber(item[col], colData.f);
      return item[col] || "-";
    }
  },
  computed: {
    rowOptions() {
      return this.pageOptions || this.defaultPageOptions;
    },
    headers() {
      if (this.exporting && this.exportCols?.length) return this.exportCols;
      return this.dataTable.headers;
    },
    slotHeaders() {
      return this.headers.filter(h => this.$slots[`item-${h.value}`]);
    },
    numericCols() {
      return this.headers.filter(h => h.type == "number" && !(this.ratingCols || []).includes(h.value)).map(h => h.value);
    },
    wrapperComponent() {
      if (this.hideExport) return "div";
      return "ExportXLSX";
    },
    dataTableRef() {
      return this.fileName.replace(/[^a-zA-Z0-9-_]/g, "-").toLowerCase();
    }
  },
  watch: {
    "dataTable.items": function () {
      if (!this.dataTable.pagination) {
        this.rowsPerPage     = this.dataTable.items.length;
        this.copyRowsPerPage = this.dataTable.items.length;
        this.updateRowsPerPage();
      }
    }
  }
}
</script>

<template>
  <component :is="wrapperComponent" :fileName="dataTableRef" :title="title" :target="dataTableRef" :titleClass="titleClass" :tooltip="tooltip" :btnText="btnText" :btnClass="btnClass" @exportXLSX="toggleRowsPerPage" :hasData="dataTable.items.length">
    <template v-slot:header>
      <label v-if="dataTable && dataTable.pagination" class="d-flex align-items-center me-4">
        <i18n-t scope="global" keypath="general.show_n_entries" tag="span" class="d-flex align-items-center" style='line-height: 2'>
          <template v-slot:n>
            <b-form-select class='ms-2 me-2' v-model="rowsPerPage" size="sm" @input="updateRowsPerPage('selector')" :options="rowOptions"></b-form-select>
          </template>
        </i18n-t>
      </label>
    </template>

    <slot name="below-title"></slot>

    <template v-if="dataTable && dataTable.items && !loading">
      <data-table
        class="mt-2"
        :id="dataTableRef"
        :ref="dataTableRef"
        :class="dataTable.tableHeaderClass"
        :items="dataTable.items"
        :headers="headers"
        :rows-per-page="rowsPerPage"
        :header-text-direction="dataTable.headerTextDirection"
        :header-item-class-name="dataTable.headerTextDirection"
        :body-text-direction="dataTable.bodyTextDirection"
        :body-item-class-name="cellClass"
        :sort-by="dataTable.sortBy"
        :sort-type="dataTable.sortType"
        hide-footer
      >
        <template v-for="col in ratingCols" #[`item-${col}`]="item" :key="col">
          <div class="h-100 d-flex justify-content-center align-items-center" :class="tdClass(item[col], item.scale)">
            {{ formatter(item, col) }}
          </div>
        </template>

        <template v-for="col in numericCols" #[`item-${col}`]="item" :key="col">
          {{ formatter(item, col) }}
        </template>

        <template v-for="(col, colIndex) in slotHeaders" #[`item-${col.value}`]="item" :key="`${dataTableRef}_col_${colIndex}`">
          <slot :name="`item-${col.value}`" :item="item"></slot>
        </template>

        <template #empty-message>
          {{ $t("general.no_data") }}
        </template>
      </data-table>

      <div class="row my-3" v-if="dataTable.pagination && dataTable.items.length">
        <div class="col">
          <div class="dataTables_paginate paging_simple_numbers float-end d-print-none">
            <ul class="pagination pagination-rounded mb-0">
              <!-- pagination -->
              <b-pagination v-model="currentPage" :total-rows="dataTable.items.length" :per-page="rowsPerPage" @update:modelValue="paginate"></b-pagination>
            </ul>
          </div>
        </div>
      </div>
    </template>
  </component>
</template>
