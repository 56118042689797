import axios from "axios";

export const state = {
  heatmap:  {}, heatmapPromise: {},
  areaIds:  {}, areaIdsPromise: {}
};

export const getters = {};

export const mutations = {
  saveHeatmap(state, { heatmap, params }) {
    state.heatmap[params] = heatmap;
  },
  saveHeatmapPromise(state, { promise, params }) {
    state.heatmapPromise[params] = promise;
  },
  saveAreaIds(state, { areaIds, params }) {
    state.areaIds[params] = areaIds;
  },
  saveAreaIdsPromise(state, { promise, params }) {
    state.areaIdsPromise[params] = promise;
  },
};

const paramsKeys = ["sub_context", "page_context", "start_date", "end_date", "group_ids", "property_type", "country", "regions", "city", "categories", "reviewer_country", "language", "tripadvisor_rating", "source", "travel_composition", "segment", "subscription_ids"].flatMap(k => [k, `exclude_${k}`]);
const areaIdsParams = ["area_ids", "type", "subscription_ids"];

export const actions = {
  async fetch({ commit, rootState }, params) {
    if (!rootState.contract.currentContract) return [];
    const contractId   = rootState.contract.currentContract.id;
    const p            = paramsKeys.reduce((h, k) => { if (params[k]) h[k] = params[k]; return h }, {});
    const stringParams = JSON.stringify({ ...p });

    if (state.heatmap[stringParams])        return state.heatmap[stringParams];
    if (state.heatmapPromise[stringParams]) return state.heatmapPromise[stringParams];

    let promise = axios.get("/v3/destination/heatmap", { params: { ...p, subscription_ids: contractId } }).then(response => {
      const heatmap = response?.data?.data || [];
      commit("saveHeatmap", { heatmap, params: stringParams });
      return heatmap;
    });

    commit("saveHeatmapPromise", { promise, params: stringParams });
    return promise;
  },
  async area_ids({ commit }, params) {
    const p            = areaIdsParams.reduce((h, k) => { if (params[k]) h[k] = params[k]; return h }, {});
    const stringParams = JSON.stringify({ ...p });

    if (state.areaIds[stringParams])        return state.areaIds[stringParams];
    if (state.areaIdsPromise[stringParams]) return state.areaIdsPromise[stringParams];

    let promise = axios.post("/v3/destination/areas", { ...p }).then(response => {
      const areaIds = response?.data?.data || [];
      commit("saveAreaIds", { areaIds, params: stringParams });
      return areaIds;
    });

    commit("saveAreaIdsPromise", { promise, params: stringParams });
    return promise;
  }
}
