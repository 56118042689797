<script>
import Utils     from '@/js/utils'
import ratings   from '@/js/ratings'
import Reports   from "@/js/reports";
import NotLoaded from "@/components/not-loaded";
import Loading   from "@/components/loading";

export default {
  data() {
    return {
      loading: true,
      error: false,
      tableData: [],
      Utils: Utils,
      reports: Reports,
      ratings: ratings,
    }
  },
  props: ['group_ids', 'start_date', 'end_date', 'group_id', 'settings'],
  components: { NotLoaded, Loading },
  methods: {
    ...Reports.requests,
    async load() {
      try {
        this.error = false
        this.loading = true
        if (!this.settings.use_custom_benchmark)
          this.settings.bench_group_ids = this.group_ids.slice(this.group_ids.length - 2)
        let r          = await this.getReports('top_segments_benchmark')
        this.tableData = r.slice(1)
      } catch {
        this.error = true
        this.loading = false
      }
    }
  },
  async mounted() {
    this.load()
  }
}
</script>
<template>
  <div>
    <table class="table table-bordered table-sm" v-if="tableData && tableData.length">
      <thead>
        <tr class="text-center">
          <th class="text-left text-capitalize">{{ $t(`reports.settings_modal.segment_options.${this.settings.segment}`) }}</th>
          <th>{{ settings.labels?.reviews_count || $t('reports.table.reviews_count') }}</th>
          <th>{{ settings.labels?.numerical_rating || $t('reports.table.numerical_rating') }}</th>
          <th>{{ settings.labels?.year_over_year || $t('reports.table.year_over_year') }}</th>
          <template v-for="benchName in reports.uniqueBenchmarkNames(tableData)" :key="benchName">
            <th>{{ benchName }}</th>
          </template>
        </tr>
      </thead>
      <tbody>
        <tr class="text-center" v-for="row in tableData" :key="row[0]">
          <td class="text-left text-capitalize">{{ translateSegment(settings.segment, row[1], row[0]) }}</td>
          <td>{{ row[2] }}</td>
          <td :class="Utils.ratings.toCss100(row[3])">{{ ratings.format(row[3] / 10) }}</td>
          <td :style="{ color: row[2] - row[5].reviews < 0 ? 'red' : 'green' }">
            {{ reports.percentDelta(row[2], row[5].reviews) }}
          </td>
          <template v-for="benchName in reports.uniqueBenchmarkNames(tableData)" :key="benchName">
            <td v-if="row[6].find(bench => bench.name === benchName)">
              {{ ratings.format(row[6].find(bench => bench.name === benchName).rating) }} %
            </td>
            <td v-else>-</td>
          </template>
        </tr>
      </tbody>
    </table>

    <Loading v-else-if="loading" />
    <NotLoaded :data="tableData" :error="error" v-else-if="!loading" />
  </div>
</template>
