<script>

export default {
  data() {
    return {
      selection: null
    };
  },
  props: ["user", "routes", "isCondensed"],
  beforeCreate() {
    document.body.setAttribute("data-sidebar", "dark");
  },
  mounted() {
    this.updateMenuSelection();
  },
  methods: {
    updateMenuSelection(v) {
      this.selection = v || this.$route.name;
    },
    expand(children = []) {
      return children.some(c => c.name == this.selection);
    }
  },
  watch: {
    "$route.path": {
      handler: function () {
        this.updateMenuSelection();
      },
      immediate: true
    }
  }
};
</script>

<template>
  <div id="sidebar-menu" v-if="user">
    <ul id="side-menu" class="metismenu list-unstyled">

      <template v-for="p in routes" :key="p.meta.name">
        <li class="menu-title">
          {{ $t(`selector.${p.meta.name}`) }}
        </li>

        <template v-if="p.children">
          <li v-for="c in p.children" :key="c.name">
            <template v-if="c.children">
              <a class="chevron d-flex justify-content-between align-items-center" data-bs-toggle="collapse" :href="`#${c.name}`" :aria-expanded="expand(c.children)" :aria-controls="c.name">
                <div>
                  <i :class="c.meta.icon"></i>
                  <span>{{ $t(`sidebar.${c.name}`) }}</span>
                </div>

                <i v-if="!isCondensed" class="mdi mdi-chevron-down"></i>
              </a>

              <ul class="sub-menu collapse" :class="{ show: expand(c.children) }" :id="c.name">
                <li v-for="c in c.children" :key="c.name">
                  <router-link :to="{ name: c.name }" class="side-nav-link-ref py-2" :class="{ active: selection == c.name }" style="padding-left:1.75rem;" @click="updateMenuSelection(c.name)">
                    <i :class="c.meta.icon"></i>
                    <span>{{ $t(`sidebar.${c.name}`) }}</span>
                  </router-link>
                </li>
              </ul>
            </template>

            <template v-else>
              <router-link :to="{ name: c.name }" class="side-nav-link-ref" :class="{ active: selection == c.name }" @click="updateMenuSelection(c.name)">
                <i :class="c.meta.icon"></i>
                <span>{{ $t(`sidebar.${c.name}`) }}</span>
              </router-link>
            </template>
          </li>
        </template>
      </template>

    </ul>
  </div>
</template>
