<script>
import {axios} from '@/js/reports'
import Utils from "@/js/utils"
import ExportExcel from "@/components/reports/export-excel"

export default {
  data() {
    return {
      cols: [
        { field: "", label: "", colspan: 10 },
        { field: "", label: "Overall Scores", colspan: 5 }
      ],
      subCols: [
        { field: "name", label: "Property Name" },
        { field: "reviews", label: "Number of Reviews" },
        { field: "positive", label: "Positive Reviews" },
        { field: "neutral", label: "Neutral Reviews" },
        { field: "negative", label: "Negative Reviews" },
        { field: "review_score", label: "Avg. Review Score" },
        { field: "position", label: "TA Rank" },
        { field: "out_of", label: "Out of" },
        { field: "location", label: "In City" },
        { field: "response_rate", label: "Review Response" },
      ],
      rows: []
    };
  },

  components: { ExportExcel },
  props: ['title', 'company', 'identifier', 'release_date', 'source', 'sourceHash'],

  created() {
    // not yet supported sources
    let sources = this.source.map(x => x) //clone to not change original
    sources.splice(sources.indexOf('open_table'), 1)
    sources.splice(sources.indexOf('yelp'), 1)
    sources.forEach(s => {
      this.subCols.push({field: s, label: this.sourceHash[s]})
    })

    axios.get("/v3/query/db/_", { params: {
      company_id:   this.company.id,
      identifier:   this.identifier,
      release_date: this.release_date,
      rk_source:    Object.keys(this.sourceHash),
      format:       'hash',
    }}).then(response => {
      this.rows = response.data.data;
      this.rows.forEach((row, i) => {
        let flattened = this.flattenObjects(row);

        if (!flattened.position && flattened.position != 0) flattened.position = "-";
        else flattened.position = "#" + flattened.position;

        if (!flattened.response_rate && flattened.response_rate != 0) flattened.response_rate = "-";
        else flattened.response_rate = flattened.response_rate + "%";

        ["review_score", "trip_advisor", "booking_com", "expedia", "hotels_com", "google"].forEach(field => {
          if (flattened[field]) flattened[field] = Utils.round(flattened[field], 2);
        });

        this.rows[i] = flattened;
      });
    });
  },
  methods: {
    flattenObjects(obj) {
      let flattened = {};
      Object.keys(obj).forEach((key) => {
        let value = obj[key];
        if (typeof value === "object" && value !== null && !Array.isArray(value))
          Object.assign(flattened, this.flattenObjects(value));
          else flattened[key] = value;
      })
      return flattened;
    }
  }
}
</script>

<template>
  <div class="table-responsive">
    <ExportExcel :file_name="`${title}.xls`" :title="title">
      <table border="1" class="table table-striped table-bordered table-nowrap mb-0 text-center">
        <thead>
          <tr>
            <th v-for="col in cols" :key="col.label" :colspan="col.colspan"> {{ col.label }} </th>
          </tr>
          <tr>
            <th class="property-name-column">{{ subCols[0].label }}</th>
            <th v-for="(subCol, index) in subCols.slice(1)" :key="subCol.field + index">{{ subCol.label }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, indexRow) in rows" :key="String(row.length) + indexRow">
            <td class="property-name-column">{{ row.name }}</td>
            <template v-for="subCol in subCols.slice(1)" :key="subCol.field + indexRow">
              <td>
                <span v-if="!row[subCol.field] && row[subCol.field] != 0">-</span>
                <span v-else>{{ row[subCol.field] }}</span>
              </td>
            </template>
          </tr>
        </tbody>
      </table>
    </ExportExcel>
  </div>
</template>
