import axios from "axios";

export const state   = { lexicons: {}, lexiconsPromise: {}, languages: null, languagesPromise: null };
export const getters = {};

export const mutations = {
  saveLexicons(state, { data, params }) {
    if (state.lexicons[params]?.length)
      state.lexicons[params] = [...data, ...state.lexicons[params]];
    else
      state.lexicons[params] = data;
  },
  saveLexiconsPromise(state, { promise, params }) {
    state.lexiconsPromise[params] = promise;
  },
  saveLanguages(state, { data }) {
    state.languages = data;
  },
  saveLanguagesPromise(state, { promise }) {
    state.languagesPromise = promise;
  }
};

const lexiconsParams = ["language_id", "contract_ids"];

export const actions = {
  async fetchLexicons({ commit }, params) {
    let o = { ...params, language_id: params.language_id || 43 };
    let p = lexiconsParams.reduce((h, k) => { if (o[k]) h[k] = o[k]; return h }, {});
    let stringParams = JSON.stringify({ ...p });

    if (state.lexicons[stringParams])        return state.lexicons[stringParams];
    if (state.lexiconsPromise[stringParams]) return state.lexiconsPromise[stringParams];

    let promise = axios.get("/v3/lexicons", { params: { ...p, only_in_contracts: true } }).then(response => {
      commit("saveLexicons", { data: response.data.data, params: stringParams });
      return response.data.data;
    });

    commit("saveLexiconsPromise", { promise, params: stringParams });
    return promise;
  },
  async fetchLanguages({ commit }) {
    if (state.languages)        return state.languages;
    if (state.languagesPromise) return state.languagesPromise;

    let promise = axios.get("/v3/lexicons/languages").then(response => {
      commit("saveLanguages", { data: response.data.data });
      return response.data.data;
    });

    commit("saveLanguagesPromise", { promise });
    return promise;
  }
}
